// var CreatedOKLodop7766=null;
//
// //====判断是否需要安装CLodop云打印服务器:====
// export function needCLodop(){
//     try{
//         var ua=navigator.userAgent;
//         if (ua.match(/Windows\sPhone/i) !=null) return true;
//         if (ua.match(/iPhone|iPod/i) != null) return true;
//         if (ua.match(/Android/i) != null) return true;
//         if (ua.match(/Edge\D?\d+/i) != null) return true;
//
//         var verTrident=ua.match(/Trident\D?\d+/i);
//         var verIE=ua.match(/MSIE\D?\d+/i);
//         var verOPR=ua.match(/OPR\D?\d+/i);
//         var verFF=ua.match(/Firefox\D?\d+/i);
//         var x64=ua.match(/x64/i);
//         if ((verTrident==null)&&(verIE==null)&&(x64!==null))
//             return true; else
//         if ( verFF !== null) {
//             verFF = verFF[0].match(/\d+/);
//             if ((verFF[0]>= 42)||(x64!==null)) return true;
//         } else
//         if ( verOPR !== null) {
//             verOPR = verOPR[0].match(/\d+/);
//             if ( verOPR[0] >= 32 ) return true;
//         } else
//         if ((verTrident==null)&&(verIE==null)) {
//             var verChrome=ua.match(/Chrome\D?\d+/i);
//             if ( verChrome !== null ) {
//                 verChrome = verChrome[0].match(/\d+/);
//                 if (verChrome[0]>=42) return true;
//             };
//         };
//         return false;
//     } catch(err) {return true;};
// };
//
// //====页面引用CLodop云打印必须的JS文件：====
// if (needCLodop()) {
//     var head = document.head || document.getElementsByTagName("head")[0] || document.documentElement;
//     var oscript = document.createElement("script");
//     oscript.src ="http://localhost:8000/CLodopfuncs.js?priority=1";
//     head.insertBefore( oscript,head.firstChild );
//
//     //引用双端口(8000和18000）避免其中某个被占用：
//     oscript = document.createElement("script");
//     oscript.src ="http://localhost:18000/CLodopfuncs.js?priority=0";
//     head.insertBefore( oscript,head.firstChild );
// };
//
// //====获取LODOP对象的主过程：====
// export function getLodop(oOBJECT,oEMBED){
//     var strHtmInstall="<br><font color='#FF00FF'>打印控件未安装!点击这里<a href='install_lodop32.exe' target='_self'>执行安装</a>,安装后请刷新页面或重新进入。</font>";
//     var strHtmUpdate="<br><font color='#FF00FF'>打印控件需要升级!点击这里<a href='install_lodop32.exe' target='_self'>执行升级</a>,升级后请重新进入。</font>";
//     var strHtm64_Install="<br><font color='#FF00FF'>打印控件未安装!点击这里<a href='install_lodop64.exe' target='_self'>执行安装</a>,安装后请刷新页面或重新进入。</font>";
//     var strHtm64_Update="<br><font color='#FF00FF'>打印控件需要升级!点击这里<a href='install_lodop64.exe' target='_self'>执行升级</a>,升级后请重新进入。</font>";
//     var strHtmFireFox="<br><br><font color='#FF00FF'>（注意：如曾安装过Lodop旧版附件npActiveXPLugin,请在【工具】->【附加组件】->【扩展】中先卸它）</font>";
//     var strHtmChrome="<br><br><font color='#FF00FF'>(如果此前正常，仅因浏览器升级或重安装而出问题，需重新执行以上安装）</font>";
//     var strCLodopInstall="<br><font color='#FF00FF'>CLodop云打印服务(localhost本地)未安装启动!点击这里<a href='http://www.c-lodop.com/download/CLodop_Setup_for_Win32NT_https_3.008Extend.zip' target='_self'>执行安装</a>,安装后请刷新页面。</font>";
//     var strCLodopUpdate="<br><font color='#FF00FF'>CLodop云打印服务需升级!点击这里<a href='CLodop_Setup_for_Win32NT.exe' target='_self'>执行升级</a>,升级后请刷新页面。</font>";
//     var LODOP;
//     try{
//         var isIE = (navigator.userAgent.indexOf('MSIE')>=0) || (navigator.userAgent.indexOf('Trident')>=0);
//         if (needCLodop()) {
//             try{ LODOP=getCLodop();} catch(err) {};
//             if (!LODOP && document.readyState!=="complete") {alert("C-Lodop没准备好，请稍后再试！"); return;};
//             if (!LODOP) {
//                 // if (isIE) document.write(strCLodopInstall); else
//                 // document.documentElement.innerHTML=strCLodopInstall+document.documentElement.innerHTML;
//                 // return;
//             } else {
//
//                 if (CLODOP.CVERSION<"3.0.0.2") {
//                     if (isIE) document.write(strCLodopUpdate); else
//                         document.documentElement.innerHTML=strCLodopUpdate+document.documentElement.innerHTML;
//                 };
//                 if (oEMBED && oEMBED.parentNode) oEMBED.parentNode.removeChild(oEMBED);
//                 if (oOBJECT && oOBJECT.parentNode) oOBJECT.parentNode.removeChild(oOBJECT);
//             };
//         } else {
//             var is64IE  = isIE && (navigator.userAgent.indexOf('x64')>=0);
//             //=====如果页面有Lodop就直接使用，没有则新建:==========
//             if (oOBJECT!=undefined || oEMBED!=undefined) {
//                 if (isIE) LODOP=oOBJECT; else  LODOP=oEMBED;
//             } else if (CreatedOKLodop7766==null){
//                 LODOP=document.createElement("object");
//                 LODOP.setAttribute("width",0);
//                 LODOP.setAttribute("height",0);
//                 LODOP.setAttribute("style","position:absolute;left:0px;top:-100px;width:0px;height:0px;");
//                 if (isIE) LODOP.setAttribute("classid","clsid:2105C259-1E0C-4534-8141-A753534CB4CA");
//                 else LODOP.setAttribute("type","application/x-print-lodop");
//                 document.documentElement.appendChild(LODOP);
//                 CreatedOKLodop7766=LODOP;
//             } else LODOP=CreatedOKLodop7766;
//             //=====Lodop插件未安装时提示下载地址:==========
//             if ((LODOP==null)||(typeof(LODOP.VERSION)=="undefined")) {
//                 if (navigator.userAgent.indexOf('Chrome')>=0)
//                     document.documentElement.innerHTML=strHtmChrome+document.documentElement.innerHTML;
//                 if (navigator.userAgent.indexOf('Firefox')>=0)
//                     document.documentElement.innerHTML=strHtmFireFox+document.documentElement.innerHTML;
//                 if (is64IE) document.write(strHtm64_Install); else
//                 if (isIE)   document.write(strHtmInstall);    else
//                     document.documentElement.innerHTML=strHtmInstall+document.documentElement.innerHTML;
//                 return LODOP;
//             };
//         };
//         if (LODOP.VERSION<"6.0") {
//             if (!needCLodop()){
//                 if (is64IE) document.write(strHtm64_Update); else
//                 if (isIE) document.write(strHtmUpdate); else
//                     document.documentElement.innerHTML=strHtmUpdate+document.documentElement.innerHTML;
//             };
//             return LODOP;
//         };
//         //===如下空白位置适合调用统一功能(如注册语句、语言选择等):===
//         //LODOP.SET_LICENSES("北京XXXXX公司","8xxxxxxxxxxxxx5","","");
//
//         //===========================================================
//         return LODOP;
//     } catch(err) {alert("getLodop出错:"+err);};
// };



//====页面动态加载C-Lodop云打印必须的文件CLodopfuncs.js====

import { getCodeLabel, getCodeOptions, isEmpty, lodopWatermark, toDate, toTime,isNumber } from '@core/utils/filter'

var head = document.head || document.getElementsByTagName("head")[0] || document.documentElement;

//让其它电脑的浏览器通过本机打印（仅适用C-Lodop自带的例子）：
var oscript = document.createElement("script");
oscript.src ="/CLodopfuncs.js";
head.insertBefore( oscript,head.firstChild );

//设置priority让本机(localhost)打印更优先一点,
//用双端口(http是8000/18000,而https是8443/8444)以防其中某端口被占:
var JS1 = document.createElement("script");
var JS2 = document.createElement("script");

if (window.location.protocol=='https:') {
    JS1.src = "https://localhost.lodop.net:8443/CLodopfuncs.js?priority=2";
    JS2.src = "https://localhost.lodop.net:8444/CLodopfuncs.js?priority=1";
} else {
    JS1.src = "http://localhost:8000/CLodopfuncs.js?priority=2";
    JS2.src = "http://localhost:18000/CLodopfuncs.js?priority=1";
}
head.insertBefore(JS1, head.firstChild);
head.insertBefore(JS2, head.firstChild);


//====获取LODOP对象的主过程：====
export function getLodop(oOBJECT,oEMBED){
    var LODOP;
    try{
        try{
            LODOP=getCLodop();
        } catch(err) {}

        if (!LODOP && document.readyState!=="complete") {
            alert("C-Lodop没准备好，请稍后再试！");
            return;
        }

        //清理原例子内的object或embed元素，避免乱提示：
        if (oEMBED && oEMBED.parentNode) oEMBED.parentNode.removeChild(oEMBED);
        if (oOBJECT && oOBJECT.parentNode) oOBJECT.parentNode.removeChild(oOBJECT);
        LODOP.SET_LICENSES("","F7226C69FEE6000C5F8AD4D1664E6874C7F","","");
        return LODOP;
    } catch(err) {alert("getLodop出错:"+err);}
}


function needCLodop(){
    return true;  //本例子强制所有浏览器都调用C-Lodop
}
//打印表格样式
export const printStyle = ()=>{
    return   '<style>' +
      'table{}' +
      'table td{font-size: 14px;white-space: nowrap;}' +
      '.proTable{border-collapse: collapse;border: none;width: 740px;}' +
      '.proTable td{border: solid #000 1px;font-size: 14px;max-width: 240px;overflow: hidden;white-space: pre-wrap;text-align: center}' +
      '.proName14{font-size: 18px}' +
      '.proName13{font-size: 17px}' +
      '.proName12{font-size: 16px}' +
      '.proName11{font-size: 15px}' +
      '.proName10{font-size: 14px}' +
      '.proName9{font-size: 13px}' +
      '.proName8{font-size: 10px}' +
      '.proName7{font-size: 7px}' +
      '.proName6{font-size: 6px}' +
      '.proName5{font-size: 5px}' +
      '</style>'
}
//根据不同的供应商选择不同的打印模板
export const reloadData = (data,LODOP)=> {
    let item = ``
    let items = data.ext.item
    let proClass = 'proName14'
    let supplierId = data.supplier_id
    let html= ``
    item = reloadItemData(proClass,supplierId,items)
    html = reloadDataHtml(data,item,supplierId)
    LODOP.SET_PRINT_STYLEA(0, 'Stretch', 2)//(可变形)扩展缩放模式
    let style = printStyle()
    lodopWatermark(LODOP)
    LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500, style + '<body size="10px">' + html + '</body>') // 增加超文本项（打印内容即为body内容）
}



export const reloadDataHtml = (data,item,supplierId) =>{
    let html= ``
    let setting  =getCodeOptions('supplier_print_setting')
    let settingLabel = "模板1"   //没有配置的供应商 默认是模版1
    setting.forEach(element =>{
        if(isNumber(element.value) === isNumber(supplierId)){
            settingLabel = element.label
        }
    })
    //style="text-align: left !important;"
    if(settingLabel === "模板1") {
        html = `
                       
                <!--表格数据-->
                <table  class="proTable">
                
                        <tr height="40px" colspan="12">
                           <td align="center" colspan="12"><font face="楷体" size="5" style="font-weight: bold">发货单</font></td>
                        </tr>
                        <tr height="20px" colspan="12">
                           <td colspan="2">供应商：</td>
                           <td colspan="4">${data.supplier_name }</td>  
                           <td colspan="1">发货日期：</td>
                           <td colspan="5">${toDate(data.send_time) }</td>                                  
                        </tr>
                        <tr height="20px" colspan="12">
                           <td colspan="2">客户名称：</td>
                           <td colspan="4">${data.company_name}</td>
                           <td colspan="1">联系人：</td>
                           <td colspan="1">${isEmpty(data.contact)?'未知':data.contact }</td>
                           <td colspan="2">联系电话：</td>
                           <td colspan="2">${ isEmpty(data.mobile)?'未知':data.mobile}</td>                      
                        </tr>
                        <tr height="20px" colspan="12">
                            <td colspan="2">收货地址：</td>
                            <td colspan="10">${isEmpty(data.location)?'未知':data.location}</td>
                        </tr>
                        <tr height="20px" colspan="12">
                           <td colspan="2">备注：</td>
                           <td colspan="10">${isEmpty(data.memo_wh)?'':data.memo_wh}</td>
                        </tr>
                        <tr height="20px" colspan="12">
                            <td colspan="2">运输方式：</td>
                            <td colspan="4">${getCodeLabel('transport_type',data.transport_type) }</td>  
                            <td colspan="1">运输单位：</td>
                            <td colspan="5">${isEmpty(data.company_name_transport)?'':data.company_name_transport }</td>                                  
                        </tr>
                        <tr height="20px" colspan="12">
                           <td colspan="2">发货编号：</td>
                           <td colspan="10">${data.in_order_no}</td>
                        </tr>
                        <tr height="25px" colspan="12">
                           <td colspan="2"  align="center"></td>
                           <td colspan="4"  align="center"></td>      
                           <td colspan="1"  align="center"></td>
                           <td colspan="1"  align="center"></td>
                           <td colspan="2"  align="center"></td>
                           <td colspan="2"  align="center"></td>         
                        </tr>
                        <tr height="25px" colspan="12">
                            <td colspan="2"  align="center">商品编码</td>
                            <td colspan="4"  align="center">商品名称</td>                
                            <td colspan="1"  align="center">单位</td>
                            <td colspan="1"  align="center">发货数量</td>
                            <td colspan="2"  align="center">发货单价</td>
                            <td colspan="2"  align="center">发货金额</td>                   
                        </tr>
                            ${item}
                        <tr height="23px" colspan="12">                     
                            <td colspan="2">合计</td>
                            <td colspan="4"></td>
                            <td colspan="1"></td>
                            <td colspan="1">${data.total_qty}</td>
                            <td colspan="2"></td>
                            <td colspan="2">${data.total_amount}</td>        
                        </tr>
                    
                        <tr height="25px" colspan="12">
                                     <td colspan="6">发货单位签章</td>
                                     <td colspan="6" >收货单位签章</td>                                      
                        </tr>  
                     
                        <tr height="80px" colspan="12">
                                   <td colspan="6"></td>
                                   <td colspan="6"></td>                                                                                
                        </tr>   
                        
                        <tr height="20px" colspan="12">                                                                              
                                    <td colspan="6" style="text-align: left !important;">签字：</td>
                                    <td colspan="6" style="text-align: left !important;">签字：</td>                                                                
                        </tr>  
                        
                        <tr height="20px" colspan="12">      
                                 <td colspan="6" style="text-align: left !important;">日期：</td>
                                 <td colspan="6" style="text-align: left !important;">日期：</td>                     
                        </td>                
                  </table>                
                
          `
    }else if(settingLabel === "模板2") {
        html = `
                          
                <!--表格数据-->
                <table  class="proTable">
                
                        <tr height="40px" colspan="12">
                           <td align="center" colspan="8"><font face="楷体" size="5" style="font-weight: bold">发货单</font></td>
                           <td align="center" colspan="4"><font face="楷体" size="4" style="font-weight: bold">发货编号：${data.in_order_no }</font></td>
                        </tr>
                        <tr height="20px" colspan="12">
                           <td colspan="2">客户名称：</td>
                           <td colspan="4">${data.company_name }</td>  
                           <td colspan="2">发货日期：</td>
                           <td colspan="4">${toDate(data.send_time) }</td>                                  
                        </tr>                      
                        <tr height="20px" colspan="12">
                            <td colspan="2">收货地址：</td>
                            <td colspan="10">${isEmpty(data.location)?'未知':data.location}</td>
                        </tr>
                        <tr height="20px" colspan="12">
                           <td colspan="2">联系人：</td>
                           <td colspan="4">${isEmpty(data.contact)?'未知':data.contact }</td>  
                           <td colspan="2">联系电话：</td>
                           <td colspan="4">${ isEmpty(data.mobile)?'未知':data.mobile}</td>                                  
                        </tr>  
                         <tr height="20px" colspan="12">
                           <td colspan="2">发货单位：</td>
                           <td colspan="4">${isEmpty(data.supplier_name)?'未知':data.supplier_name }</td>  
                           <td colspan="2">运输单位：</td>
                           <td colspan="4">${isEmpty(data.company_name_transport)?'':data.company_name_transport }</td>                                  
                        </tr>                                                             
                        <tr height="25px" colspan="12">
                            <td colspan="2"  align="center">序号</td>
                            <td colspan="4"  align="center">商品名称</td>                
                            <td colspan="3"  align="center">规格型号</td>
                            <td colspan="3"  align="center">发货数量</td>                                           
                        </tr>
                            ${item}
                        <tr height="23px" colspan="12">                     
                            <td colspan="2">合计</td>
                            <td colspan="4"></td>                          
                            <td colspan="3"></td>
                            <td colspan="2">${isEmpty(data.total_qty)?"":data.total_qty}</td>        
                        </tr>                   
                        <tr height="25px" colspan="12">
                                     <td colspan="6">发货人</td>
                                     <td colspan="3">承运人</td>                                      
                                     <td colspan="3">收货人</td>                                      
                        </tr>  
                                            
                        <tr height="25px" colspan="12">
                                     <td colspan="6"></td>
                                     <td colspan="3"></td>                                      
                                     <td colspan="3"></td>                                                                                   
                        </tr>   
                  </table>
                   <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td align="left"><font size="2px">备注：</font></td>                       
                    </tr>    
                    <tr height="25px">
                        <td align="left"><font size="2px">1.承运人确认运单上货物数量及包装整洁完好，并保证货物安全准时完好送到收货人手中。</font></td>                       
                    </tr>    
                    <tr height="25px">
                       
                        <td align="left"><font size="2px">2.发货方详细填写运单，并按期结款。</font></td>
                      
                    </tr>   
                    <tr height="25px">
                      
                        <td align="left"><font size="2px">3.货物在运输中发生损坏、丢失或者其他异常情况，承运方要在第一时间通知发货人，并根据实际情况按价赔偿。</font></td>
                     
                    </tr>   
                    <tr height="25px">
                       
                        <td align="left"><font size="2px">4.收货方确认收货后，签字\\盖章生效。</font></td>
                    </tr>                
                </table>                
                
          `
    }else if(settingLabel === "模板3") {
        html = `
                            
                <!--表格数据-->
                <table  class="proTable">
                
                         <tr height="40px" colspan="12">
                           <td align="center" colspan="10"><font face="楷体" size="5" style="font-weight: bold">发货单</font></td>
                           <td align="center" colspan="2"><font face="楷体" size="4" style="font-weight: bold">编号：${data.in_order_no}</font></td>
                        </tr>
                         <tr height="20px" colspan="12">
                           <td colspan="2">客户名称：</td>
                           <td colspan="4">${data.company_name }</td>  
                           <td colspan="1">订单号：</td>
                           <td colspan="3">${data.order_no}</td>
                           <td colspan="2" rowspan="4">备注：${isEmpty(data.memo_wh)?'':data.memo_wh}</td>                                                          
                        </tr>
                         <tr height="20px" colspan="12">
                           <td colspan="2">收货地址：</td>
                           <td colspan="4">${isEmpty(data.location)?'未知':data.location}</td>  
                           <td colspan="1">订单日期：</td>
                           <td colspan="3">${toDate(data.add_date)}</td>
                                                                                 
                        </tr>
                        <tr height="20px" colspan="12">
                           <td colspan="2">联系人：</td>
                           <td colspan="4">${isEmpty(data.contact)?'未知':data.contact }</td>  
                           <td colspan="1">销售单位：</td>
                           <td colspan="3">${data.supplier_name}</td>
                                                                                  
                        </tr>
                         <tr height="20px" colspan="12">
                           <td colspan="2">联系电话：</td>
                           <td colspan="4">${ isEmpty(data.mobile)?'未知':data.mobile}</td>  
                           <td colspan="1">运输方式：</td>
                           <td colspan="3">${getCodeLabel('transport_type',data.transport_type) }</td>
                                                                                   
                        </tr>                    
                        <tr height="25px" colspan="12">
                            <td colspan="2"  align="center">行号</td>
                            <td colspan="4"  align="center">商品名称</td>                
                            <td colspan="1"  align="center">规格型号</td>
                            <td colspan="1"  align="center">单位</td>
                            <td colspan="1"  align="center">发货数量</td>
                            <td colspan="1"  align="center">发货单价</td>
                            <td colspan="1"  align="center">发货金额</td>                   
                            <td colspan="1"  align="center">发货重量</td>                   
                        </tr>
                            ${item}   
                         <tr height="25px" colspan="12">
                            <td colspan="2"  align="center">合计：</td>
                            <td colspan="4"  align="center"></td>                
                            <td colspan="1"  align="center"></td>
                            <td colspan="1"  align="center"></td>
                            <td colspan="1"  align="center">${isEmpty(data.total_qty)?"":data.total_qty}</td>
                            <td colspan="1"  align="center"></td>
                            <td colspan="1"  align="center">${isEmpty(data.total_amount)?"":data.total_amount}</td>                   
                            <td colspan="1"  align="center"></td>                   
                        </tr>                                    
                        <tr height="50px" colspan="12" >
                                     <td colspan="6" >发货时间：${isEmpty(data.send_time)?'':toTime(data.send_time) } </td>
                                     <td colspan="3" rowspan="2" style="margin-top: 0px ;vertical-align: baseline">销售发货签字盖章：</td>                                      
                                     <td colspan="3" rowspan="2" style="margin-top: 0px ;vertical-align: baseline">客户收货签字盖章：</td>                                      
                        </tr>  
                     
                        <tr height="50px" colspan="12" >
                                    <td colspan="6" >到货时间：${toTime(data.delivery_time) }</td>                                                                                                                
                        </tr>   
                        
                        <tr height="20px" colspan="12">                                                                              
                                    <td colspan="6">运输方式：${isEmpty(data.transport_type)?'':getCodeLabel('transport_type',data.transport_type)}</td>
                                    <td colspan="3">承运单位：${isEmpty(data.company_name_transport)?'':data.company_name_transport }</td>                                                                
                                    <td colspan="3">车号：${isEmpty(data.car_no)?'':data.car_no }</td>                                                                
                        </tr>  
                        
                                
                  </table>                
                
          `
    }else if(settingLabel === "模板4") {
        html = `
                            
                <!--表格数据-->
                <table  class="proTable">
                
                         <tr height="40px" colspan="12">
                           <td align="center" colspan="12"><font face="楷体" size="5" style="font-weight: bold">产品发货单:${data.in_order_no}</font></td>                          
                        </tr>                                            
                        <tr height="25px" colspan="12">
                            <td colspan="1"  align="center">序号</td>
                            <td colspan="4"  align="center">商品名称</td>                                          
                            <td colspan="1"  align="center">单位</td>
                            <td colspan="2"  align="center">发货数量</td>
                            <td colspan="2"  align="center">发货单价</td>
                            <td colspan="2"  align="center">发货金额</td>                                                       
                        </tr>
                            ${item}                                       
                        <tr height="50px" colspan="12" >
                            <td colspan="1"  align="center">备注</td>
                            <td colspan="4"  align="center">${isEmpty(data.memo_wh)?'':data.memo_wh}</td>                                          
                            <td colspan="1"  align="center">合计</td>
                            <td colspan="2"  align="center">${isEmpty(data.total_qty)?'':data.total_qty}</td>
                            <td colspan="2"  align="center"></td>
                            <td colspan="2"  align="center">${isEmpty(data.total_amount)?'':data.total_amount}</td>                                                     
                        </tr>  
                        
                        <tr height="50px" colspan="12" >   
                            <td colspan="12"></td>                                                                      
                        </tr>  
                     
                        <tr height="25px" colspan="12" >
                                    <td colspan="6" >售货方：${data.supplier_name }</td>   
                                    <td colspan="6" >订货方：${data.company_name }</td>                                                                                                                
                        </tr>   
                        
                        <tr height="25px" colspan="12" >
                                    <td colspan="6" >订货联系人：${isEmpty(data.contact)?'未知':data.contact }</td>   
                                    <td colspan="6" >订货联系方式：${ isEmpty(data.mobile)?'未知':data.mobile}</td>                                                                                                                
                        </tr> 
                        
                        <tr height="25px" colspan="12" >
                                    <td colspan="12" >订货地址：${isEmpty(data.location)?'未知':data.location}</td>                                                                                                                                              
                        </tr> 
                        
                        <tr height="25px" colspan="12" >
                                    <td colspan="6" >发货日期：${isEmpty(data.send_time)?'':toDate(data.send_time) }</td>   
                                    <td colspan="6" >到货日期：${isEmpty(data.delivery_time)?'':toDate(data.delivery_time) }</td>                                                                                                                
                        </tr>
                        
                        <tr height="50px" colspan="12" >
                                    <td colspan="6" style="text-align: left !important;">发货人签字：</td>   
                                    <td colspan="6" style="text-align: left !important;">收货人签字：</td>                                                                                                                
                        </tr>                                                                                                 
                  </table>  
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td align="left"><font size="2px">配送物流：${isEmpty(data.company_name_transport)?'':data.company_name_transport }</font></td>                       
                    </tr>    
                    <tr height="25px">
                        <td align="left"><font size="2px">如果商品有问题，请及时与我们联系！</font></td>                       
                    </tr>                       
                </table>                          
                
          `
    }else if(settingLabel === "模板5") {
        html = `
                            
                <!--表格数据-->
                <table  class="proTable">
                
                        <tr height="40px" colspan="12">
                           <td align="center" colspan="12"><font face="楷体" size="5" style="font-weight: bold">公司销售发货单</font></td>                          
                        </tr>   
                        
                         <tr height="25px" colspan="12" >
                                    <td colspan="6" >发货单号：${data.in_order_no }</td>   
                                    <td colspan="6" >发货单位：${isEmpty(data.supplier_name)?'未知':data.supplier_name }</td>                                                                                                                
                        </tr> 
                        
                        <tr height="25px" colspan="12" >
                                    <td colspan="6" >客户名称：${data.company_name }</td>   
                                    <td colspan="6" >联系电话：${isEmpty(data.mobile)?'未知':data.mobile }</td>                                                                                                                
                        </tr> 
                        
                        <tr height="25px" colspan="12" >
                                    <td colspan="12" >收货地址：${isEmpty(data.location)?'未知':data.location}</td>                                                                                                                                              
                        </tr>  
                                            
                        <tr height="25px" colspan="12">
                            <td colspan="1"  align="center">序号</td>
                            <td colspan="4"  align="center">商品名称</td>                                          
                            <td colspan="2"  align="center">规格型号</td>
                            <td colspan="1"  align="center">单位</td>
                            <td colspan="2"  align="center">发货数量</td>
                            <td colspan="2"  align="center">发货金额</td>                                                       
                        </tr>
                            ${item}                                       
                        <tr height="25px" colspan="12" >
                            <td colspan="1"  align="center">合计</td>
                            <td colspan="4"  align="center"></td>                                          
                            <td colspan="2"  align="center"></td>
                            <td colspan="1"  align="center"></td>
                            <td colspan="2"  align="center">${isEmpty(data.total_qty)?'':data.total_qty}</td>
                            <td colspan="2"  align="center">${isEmpty(data.total_amount)?'':data.total_amount}</td>                                                     
                        </tr>  
                        
                        <tr height="50px" colspan="12" >   
                            <td colspan="12" style="text-align: left !important;">备注：${isEmpty(data.memo_wh)?'':data.memo_wh}</td>                                                                      
                        </tr>  
                                                                       
                  </table>  
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td align="left"><font size="2px">收货确认：</font></td>                       
                    </tr>                                  
                </table>                          
                
          `
    }else if(settingLabel === "模板6") {
        html = `
                       
                <!--表格数据-->
                <table  class="proTable">
                
                        <tr height="40px" colspan="12">
                           <td align="center" colspan="12"><font face="楷体" size="5" style="font-weight: bold">发货单</font></td>
                        </tr>
                        <tr height="25px" colspan="12">
                           <td colspan="2">发货时间：</td>
                           <td colspan="5" >${isEmpty(data.send_time)?'':toTime(data.send_time) } </td>
                           <td colspan="1">发货单号：</td>
                           <td colspan="4">${data.in_order_no}</td>                                  
                        </tr>
                        <tr height="25px" colspan="12">
                           <td colspan="2" rowspan="2">购货单位：</td>
                           <td colspan="5">${data.company_name}</td>
                           <td colspan="1">联系人：</td>
                           <td colspan="4">${isEmpty(data.contact)?'未知':data.contact }</td>                                           
                        </tr>
                        <tr height="25px" colspan="12">                         
                           <td colspan="5">${isEmpty(data.location)?'未知':data.location}</td>
                           <td colspan="1">联系电话：</td>
                           <td colspan="4">${isEmpty(data.mobile)?'未知':data.mobile }</td>                                           
                        </tr>
                                                                 
                        <tr height="25px" colspan="12">
                            <td colspan="2"  align="center">商品编码</td>
                            <td colspan="4"  align="center">商品名称</td>                
                            <td colspan="1"  align="center">规格型号</td>                
                            <td colspan="1"  align="center">单位</td>
                            <td colspan="1"  align="center">发货数量</td>
                            <td colspan="1"  align="center">发货单价</td>
                            <td colspan="2"  align="center">发货金额</td>                   
                        </tr>
                            ${item}
                        <tr height="40px" colspan="12">                     
                            <td colspan="2">发货单位：</td>
                            <td colspan="5">${data.supplier_name}</td>
                            <td colspan="1">合计</td>
                            <td colspan="1">${data.total_qty}</td>
                            <td colspan="1"></td>
                            <td colspan="2">¥${data.total_amount}</td>        
                        </tr>
                         <tr height="40px" colspan="12">                     
                            <td colspan="8" style="text-align: left !important;">运输单位：${isEmpty(data.company_name_transport)?'':data.company_name_transport }</td>
                            <td colspan="5" style="text-align: left !important;">收货签字：</td>                              
                        </tr>
                  </table>  
          `
    }else if(settingLabel === "模板7") {
        html = `
                       
                <!--表格数据-->
                <table  class="proTable">
                
                        <tr height="40px" colspan="12">
                           <td align="center" colspan="12"><font face="楷体" size="5" style="font-weight: bold">发货单</font></td>
                        </tr>
                        <tr height="25px" colspan="12">
                           <td colspan="2">发货编码：</td>
                           <td colspan="4" >${data.in_order_no } </td>
                           <td colspan="1">发货日期：</td>
                           <td colspan="4">${isEmpty(data.send_time)?'':toDate(data.send_time) }</td>                                  
                        </tr>
                        <tr height="25px" colspan="12">
                           <td colspan="2" >客户名称：</td>
                           <td colspan="4">${data.company_name}</td>
                           <td colspan="1">联系人：</td>
                           <td colspan="1">${isEmpty(data.contact)?'未知':data.contact }</td>  
                           <td colspan="2">联系电话：</td>
                           <td colspan="2">${isEmpty(data.mobile)?'未知':data.mobile }</td>                                             
                        </tr>
                      
                        
                         <tr height="25px" colspan="12">  
                           <td colspan="2">收货地址：</td>                       
                           <td colspan="10">${isEmpty(data.location)?'未知':data.location}</td>
                                                                                              
                        </tr>
                                                                 
                        <tr height="25px" colspan="12">
                            <td colspan="2"  align="center">商品编码</td>
                            <td colspan="4"  align="center">商品名称</td>                
                            <td colspan="1"  align="center">规格型号</td>                
                            <td colspan="1"  align="center">单位</td>
                            <td colspan="2"  align="center">发货数量</td>                           
                            <td colspan="2"  align="center">发货金额</td>                   
                        </tr>
                            ${item}
                        <tr height="40px" colspan="12">                     
                            <td colspan="2">合计：</td>
                            <td colspan="4"></td>
                            <td colspan="1"></td>
                            <td colspan="1"></td>
                            <td colspan="2">${data.total_qty}</td>                           
                            <td colspan="2">¥${data.total_amount}</td>        
                        </tr>
                         <tr height="40px" colspan="12">                     
                            <td colspan="6" style="text-align: left !important;">供应商：${data.supplier_name }</td>
                            <td colspan="6" style="text-align: left !important;">承运商：${isEmpty(data.company_name_transport)?'':data.company_name_transport }</td>                              
                        </tr>
                        <tr height="40px" colspan="12">                     
                            <td colspan="12" style="text-align: left !important;">收货确认签字：</td>                                                   
                        </tr>
                  </table>  
          `
    }else if(settingLabel === "模板8") {
        html = `
                       
                <!--表格数据-->
                <table  class="proTable">
                
                        <tr height="40px" colspan="12">
                           <td align="center" colspan="12"><font face="楷体" size="5" style="font-weight: bold">发货单</font></td>
                        </tr>
                        <tr height="25px" colspan="12">
                           <td colspan="2">客户名称：</td>
                           <td colspan="4" >${data.company_name } </td>
                           <td colspan="1">发货日期：</td>
                           <td colspan="4">${isEmpty(data.send_time)?'':toDate(data.send_time) }</td>                                  
                        </tr>   
                                                           
                         <tr height="25px" colspan="12">  
                           <td colspan="3">收货地址：</td>                       
                           <td colspan="9">${isEmpty(data.location)?'未知':data.location}</td>
                                                                                              
                        </tr>
                        
                        <tr height="25px" colspan="12">  
                           <td colspan="3">联系人：</td>                       
                           <td colspan="4">${isEmpty(data.contact)?'未知':data.contact }</td>
                           <td colspan="1" >联系电话</td>                                           
                           <td colspan="4" >${isEmpty(data.mobile)?'未知':data.mobile }</td>                                                                                                                         
                        </tr>
                        
                        <tr height="25px" colspan="12">  
                           <td colspan="3">发货单位：</td>                       
                           <td colspan="4">${data.supplier_name}</td>
                           <td colspan="1" >发货编码：</td>                                           
                           <td colspan="4" >${data.in_order_no }</td>                                                                                                                         
                        </tr>
                        
                        <tr height="25px" colspan="12">  
                           <td colspan="3">备注：</td>                       
                           <td colspan="4">${isEmpty(data.memo_wh)?'':data.memo_wh}</td>
                           <td colspan="1" ></td>                                           
                           <td colspan="2" ></td>                           
                           <td colspan="2" ></td>                                                                       
                        </tr>
                                                                 
                        <tr height="25px" colspan="12">
                            <td colspan="1"  align="center">序号</td>
                            <td colspan="2"  align="center">商品编码</td>
                            <td colspan="4"  align="center">商品名称</td>                
                            <td colspan="1"  align="center">规格型号</td>                                           
                            <td colspan="2"  align="center">发货数量</td>                           
                            <td colspan="2"  align="center">发货金额</td>                   
                        </tr>
                            ${item}
                        <tr height="40px" colspan="12"> 
                            <td colspan="1">合计:</td>                    
                            <td colspan="2"></td>
                            <td colspan="4"></td>
                            <td colspan="1"></td>
                            <td colspan="2">${data.total_qty}</td>                           
                            <td colspan="2">¥${data.total_amount}</td>        
                        </tr>
                         <tr height="40px" colspan="12">                     
                            <td colspan="7" style="text-align: left !important;">发货确认：</td>
                            <td colspan="5" style="text-align: left !important;">收货确认：</td>                              
                        </tr>                      
                  </table>  
                   <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr height="25px">
                            <td align="left"><font size="2px">备注：</font></td>                       
                        </tr>    
                        <tr height="25px">
                            <td align="left"><font size="2px">1.发货方详细填写运单，并按期结款。</font></td>                       
                        </tr>  
                        <tr height="25px">
                            <td align="left"><font size="2px">2.收货方确认收货后，签字/盖章生效。</font></td>                       
                        </tr>                        
                    </table> 
          `
    }else if(settingLabel === "模板9") {
        html = `
                       
                <!--表格数据-->
                <table  class="proTable">
                
                        <tr height="40px" colspan="12">
                           <td align="center" colspan="7" ><font face="楷体" size="5" style="font-weight: bold">发货单</font></td>
                           <td align="center" colspan="5">编号：${data.in_order_no} \n  发货单位：${data.supplier_name}</td>
        
                        </tr>  
                       
                                           
                        <tr height="25px" colspan="12">                         
                           <td colspan="6">客户名称：${isEmpty(data.company_name)?'':data.company_name } \n 收货地址：${isEmpty(data.location)?'未知':data.location } </td>                                                                                        
                           <td colspan="6">联系人：${isEmpty(data.contact) ? '未知' : data.contact} \n 联系电话：${isEmpty(data.mobile) ? '未知' : data.mobile}</td>    
                        </tr>
                                            
                        <tr height="25px" colspan="12">                                                  
                           <td colspan="12" style="text-align: left !important;">备注：${isEmpty(data.memo_wh)?'':data.memo_wh}</td>                                                                
                        </tr>
                        
                                                                 
                        <tr height="25px" colspan="12">
                            <td colspan="1"  align="center">序号</td>
                            <td colspan="4"  align="center">商品名称</td>                
                            <td colspan="1"  align="center">规格型号</td>                
                            <td colspan="1"  align="center">单位</td>
                            <td colspan="1"  align="center">发货数量</td>
                            <td colspan="2"  align="center">发货单价</td>
                            <td colspan="2"  align="center">发货金额</td>                   
                        </tr>
                            ${item}
                         <tr height="25px" colspan="12">
                            <td colspan="1"  align="center">合计：</td>
                            <td colspan="4"  align="center"></td>                
                            <td colspan="1"  align="center"></td>                
                            <td colspan="1"  align="center"></td>
                            <td colspan="1"  align="center">${data.total_qty}</td>
                            <td colspan="2"  align="center"></td>
                            <td colspan="2"  align="center">${data.total_amount}</td>                   
                       </tr>   
                       <tr height="50px" colspan="12" >
                                     <td colspan="6" >发货时间：${isEmpty(data.send_time)?'':toTime(data.send_time) } </td>
                                     <td colspan="3" rowspan="2" style="margin-top: 0px ;vertical-align: baseline">销售发货签字盖章：</td>                                      
                                     <td colspan="3" rowspan="2" style="margin-top: 0px ;vertical-align: baseline">客户收货签字盖章：</td>                                      
                        </tr> 
                         
                        <tr height="50px" colspan="12" >
                                    <td colspan="6" >到货时间：${toTime(data.delivery_time) }</td>                                                                                                                
                        </tr>   
                  </table>  
          `
    }else if(settingLabel === "模板10") {
        html = `
                            
                <!--表格数据-->
                <table  class="proTable">
                
                         <tr height="40px" colspan="12">
                           <td align="center" colspan="12"><font face="楷体" size="5" style="font-weight: bold">产品发货单：${data.in_order_no }</font></td>                          
                        </tr>   
                        <tr height="25px" colspan="12" >
                                    <td colspan="6" style="text-align: left !important;">售货方：${data.supplier_name }</td>   
                                    <td colspan="6" style="text-align: left !important;">订货方：${data.company_name }</td>                                                                                                                
                        </tr>   
                        
                        <tr height="25px" colspan="12" >
                                    <td colspan="6" style="text-align: left !important;">订货联系人：${isEmpty(data.contact)?'未知':data.contact }</td>   
                                    <td colspan="6" style="text-align: left !important;">订货联系方式：${ isEmpty(data.mobile)?'未知':data.mobile}</td>                                                                                                                
                        </tr> 
                                              
                        
                        <tr height="25px" colspan="12" >
                                    <td colspan="6" style="text-align: left !important;">发货日期：${isEmpty(data.send_time)?'':toDate(data.send_time) }</td>   
                                    <td colspan="6" style="text-align: left !important;">到货日期：${isEmpty(data.delivery_time)?'':toDate(data.delivery_time) }</td>                                                                                                                
                        </tr>                    
                        <tr height="25px" colspan="12">
                            <td colspan="1"  align="center">序号</td>
                            <td colspan="5"  align="center">商品名称</td>                                          
                            <td colspan="1"  align="center">单位</td>
                            <td colspan="1"  align="center">发货数量</td>
                            <td colspan="2"  align="center">发货单价</td>
                            <td colspan="2"  align="center">发货金额</td>                                                       
                        </tr>
                            ${item}                                       
                        <tr height="50px" colspan="12" >
                            <td colspan="1"  align="center">备注</td>
                            <td colspan="5"  align="center">${isEmpty(data.memo_wh)?'':data.memo_wh}</td>                                          
                            <td colspan="1"  align="center">合计</td>
                            <td colspan="1"  align="center">${isEmpty(data.total_qty)?'':data.total_qty}</td>
                            <td colspan="2"  align="center"></td>
                            <td colspan="2"  align="center">${isEmpty(data.total_amount)?'':data.total_amount}</td>                                                     
                        </tr>  
                       
                        <tr height="50px" colspan="12" >
                                    <td colspan="6" style="text-align: left !important;">发货人签字：</td>   
                                    <td colspan="6" style="text-align: left !important;">收货人签字：</td>                                                                                                                
                        </tr>                                                                                                 
                  </table>  
                                  
               
          `
    }else if(settingLabel === "模板11") {
        html = `
                       
                <!--表格数据-->
                 <table width="740px" border="0" cellspacing="0" cellpadding="0">
                        <tr height="40px" colspan="12">
                           <td align="center" colspan="12"><font face="楷体" size="5" style="font-weight: bold">${data.supplier_name }发货单</font></td>
                        </tr>
                        <tr height="25px">
                            <td align="left"><font size="2px">发货单号：${data.in_order_no}</font></td>                       
                            <td align="left"><font size="2px">订货方：${data.company_name }</font></td>                       
                        </tr>    
                        <tr height="25px">
                            <td align="left"><font size="2px">备注：${isEmpty(data.memo_wh)?'':data.memo_wh}</font></td>                       
                        </tr>                       
                </table>   
                <table  class="proTable">                                                        
                        <tr height="25px" colspan="12">
                            <td colspan="2"  align="center">商品编码</td>
                            <td colspan="4"  align="center">商品名称</td>                
                            <td colspan="1"  align="center">规格型号</td>                
                            <td colspan="1"  align="center">单位</td>
                            <td colspan="2"  align="center">发货数量</td>                           
                            <td colspan="2"  align="center">发货金额</td>                   
                        </tr>
                            ${item}
                        <tr height="40px" colspan="12">                     
                            <td colspan="2">合计：</td>
                            <td colspan="4"></td>
                            <td colspan="1"></td>
                            <td colspan="1"></td>
                            <td colspan="2">${data.total_qty}</td>                           
                            <td colspan="2">${data.total_amount}</td>        
                        </tr>
                        
                        <tr height="40px" colspan="12">      
                            <td colspan="6" style="text-align: left !important;">发货日期：${isEmpty(data.send_time)?'':toDate(data.send_time) }</td>                   
                            <td colspan="6" style="text-align: left !important;">收货签字：</td>                                                   
                        </tr>
                  </table>  
        `
    } else if(settingLabel === "模板12") {
        html = `
                       
                <!--表格数据-->
                <table  class="proTable">
                
                        <tr height="40px" colspan="12">
                           <td align="center" colspan="12"><font face="楷体" size="5" style="font-weight: bold">发货单</font></td>
                        </tr>
                        <tr height="25px" colspan="12">
                           <td colspan="2">发货时间：</td>
                           <td colspan="5" >${isEmpty(data.send_time)?'':toTime(data.send_time) } </td>
                           <td colspan="1">发货单号：</td>
                           <td colspan="4">${data.in_order_no}</td>                                  
                        </tr>
                        
                        <tr height="25px" colspan="12">
                           <td colspan="2" >发货单位：</td>
                           <td colspan="5">${isEmpty(data.supplier_name)?'':data.supplier_name }</td>
                           <td colspan="1">收货单位：</td>
                           <td colspan="4">${isEmpty(data.company_name)?'':data.company_name }</td>                                           
                        </tr>
                       
                        
                        <tr height="25px" colspan="12">
                           <td colspan="2" >联系人：</td>
                           <td colspan="5">${isEmpty(data.contact)?'未知':data.contact }</td>
                           <td colspan="1">联系电话：</td>
                           <td colspan="4">${isEmpty(data.mobile)?'未知':data.mobile }</td>                                           
                        </tr>
                       
                                                                 
                        <tr height="25px" colspan="12">
                            <td colspan="2"  align="center">商品编码</td>
                            <td colspan="4"  align="center">商品名称</td>                
                            <td colspan="1"  align="center">规格型号</td>                
                            <td colspan="1"  align="center">单位</td>
                            <td colspan="1"  align="center">发货数量</td>
                            <td colspan="1"  align="center">发货单价</td>
                            <td colspan="2"  align="center">发货金额</td>                   
                        </tr>
                            ${item}                                                  
                        <tr height="40px" colspan="12">                     
                            <td colspan="2">收货签字：</td>
                            <td colspan="5"></td>
                            <td colspan="1">合计</td>
                            <td colspan="1">${data.total_qty}</td>
                            <td colspan="1"></td>
                            <td colspan="2">¥${data.total_amount}</td>        
                        </tr>
                        
                  </table>  
          `
    }
    return html
}


export const reloadItemData =  (proClass,supplierId,items) => {
    let item = ``
    // 测试循黄
    let setting  =getCodeOptions('supplier_print_setting')
    let settingLabel = "模板1"   //没有配置的供应商 默认是模版1
    setting.forEach(element =>{
        if(isNumber(element.value) === isNumber(supplierId)){
            settingLabel = element.label
        }
    })
    if(settingLabel === "模板1"){
        for (let j = 0; j < items.length; j++) {
            item += `   <tr height="23px">
                        <td colspan="2">${items[j].ext.code}</td>
                        <td colspan="4" >${items[j].name}</td>
                        <td colspan="1">${items[j].ext.product_unit}</td>
                        <td colspan="1">${items[j].trueqty}</td>
                        <td colspan="2">${ items[j].product_cost_tax }</td>
                        <td colspan="2">${items[j].subtotal}</td>
                    </tr>`
        }
    }
     else if (settingLabel === "模板2"){
        for (let j = 0; j < items.length; j++) {
            item += `   <tr height="23px">
                        <td colspan="2" align="center">${j+1}</td>
                        <td colspan="4">${items[j].name}</td>
                        <td colspan="3">${items[j].specification}</td>
                        <td colspan="3">${items[j].trueqty}</td>                     
                    </tr>`
        }
    }
     else if (settingLabel === "模板3"){
        for (let j = 0; j < items.length; j++) {
            item += `   <tr height="23px">
                        <td colspan="2" align="center">${j+1}</td>
                        <td colspan="4">${items[j].name}</td>
                        <td colspan="1">${items[j].specification}</td>                      
                        <td colspan="1">${items[j].ext.product_unit}</td>
                        <td colspan="1">${items[j].trueqty}</td>
                        <td colspan="1">${ items[j].product_cost_tax }</td>
                        <td colspan="1">${items[j].subtotal}</td>                  
                        <td colspan="1">${isEmpty(items[j].total_weight)?'':items[j].total_weight }</td>                  
                    </tr>`
        }
    }
     else if (settingLabel === "模板4"){
        for (let j = 0; j < items.length; j++) {
            item += `   <tr height="23px">
                        <td colspan="1" align="center">${j+1}</td>
                        <td colspan="4">${items[j].name}</td>
                        <td colspan="1">${items[j].ext.product_unit}</td>                      
                        <td colspan="2">${items[j].trueqty}</td>
                        <td colspan="2">${items[j].product_cost_tax}</td>
                        <td colspan="2">${ items[j].subtotal }</td>                               
                    </tr>`
        }
    }
     else if (settingLabel === "模板5"){
        for (let j = 0; j < items.length; j++) {
            item += `   <tr height="23px">
                        <td colspan="1" align="center">${j+1}</td>
                        <td colspan="4">${items[j].name}</td>
                        <td colspan="2">${items[j].specification}</td>                      
                        <td colspan="1">${items[j].ext.product_unit}</td>
                        <td colspan="2">${items[j].trueqty}</td>
                        <td colspan="2">${ items[j].subtotal }</td>                               
                    </tr>`
        }
    }
     else if (settingLabel === "模板6"){
        for (let j = 0; j < items.length; j++) {
            item += `   <tr height="23px">
                        <td colspan="2">${items[j].ext.code}</td>
                        <td colspan="4">${items[j].name}</td>
                        <td colspan="1">${items[j].specification}</td>                      
                        <td colspan="1">${items[j].ext.product_unit}</td>
                        <td colspan="1">${items[j].trueqty}</td>
                        <td colspan="1">${ items[j].product_cost_tax }</td>                               
                        <td colspan="2">${ items[j].subtotal }</td>                               
                    </tr>`
        }
    }
     else if (settingLabel === "模板7"){
        for (let j = 0; j < items.length; j++) {
            item += `   <tr height="23px">
                        <td colspan="2">${items[j].ext.code}</td>
                        <td colspan="4">${items[j].name}</td>
                        <td colspan="1">${items[j].specification}</td>                      
                        <td colspan="1">${items[j].ext.product_unit}</td>
                        <td colspan="2">${items[j].trueqty}</td>                                                
                        <td colspan="2">${ items[j].subtotal }</td>                               
                    </tr>`
        }
    }
     else if (settingLabel === "模板8"){
        for (let j = 0; j < items.length; j++) {
            item += `   <tr height="23px">
                        <td colspan="1">${j+1}</td>
                        <td colspan="2">${items[j].ext.code}</td>
                        <td colspan="4">${items[j].name}</td>
                        <td colspan="1">${items[j].specification}</td>                                              
                        <td colspan="2">${items[j].trueqty}</td>                                                
                        <td colspan="2">${ items[j].subtotal }</td>                               
                    </tr>`
        }
    }
    else if (settingLabel === "模板9"){
        for (let j = 0; j < items.length; j++) {
            item += `   <tr height="23px">
                        <td colspan="1">${j+1}</td>
                        <td colspan="4">${items[j].name}</td>
                        <td colspan="1">${items[j].specification}</td>                      
                        <td colspan="1">${items[j].ext.product_unit}</td>
                        <td colspan="1">${items[j].trueqty}</td>
                        <td colspan="2">${ items[j].product_cost_tax }</td>                               
                        <td colspan="2">${ items[j].subtotal }</td>                               
                    </tr>`
        }
    }
    else if (settingLabel === "模板10"){
        for (let j = 0; j < items.length; j++) {
            item += `   <tr height="23px">
                        <td colspan="1" align="center">${j+1}</td>
                        <td colspan="5">${items[j].name}</td>
                        <td colspan="1">${items[j].ext.product_unit}</td>                      
                        <td colspan="1">${items[j].trueqty}</td>
                        <td colspan="2">${items[j].product_cost_tax}</td>
                        <td colspan="2">${ items[j].subtotal }</td>                               
                    </tr>`
        }
    }
    else if (settingLabel === "模板11"){
        for (let j = 0; j < items.length; j++) {
            item += `   <tr height="23px">
                        <td colspan="2">${items[j].ext.code}</td>
                        <td colspan="4">${items[j].name}</td>
                        <td colspan="1">${items[j].specification}</td>                      
                        <td colspan="1">${items[j].ext.product_unit}</td>
                        <td colspan="2">${items[j].trueqty}</td>                                                
                        <td colspan="2">${ items[j].subtotal }</td>                               
                    </tr>`
        }
    }
    else if (settingLabel === "模板12"){
        for (let j = 0; j < items.length; j++) {
            item += `   <tr height="23px">
                        <td colspan="2">${items[j].ext.code}</td>
                        <td colspan="4">${items[j].name}</td>
                        <td colspan="1">${items[j].specification}</td>                      
                        <td colspan="1">${items[j].ext.product_unit}</td>
                        <td colspan="1">${items[j].trueqty}</td>
                        <td colspan="1">${ items[j].product_cost_tax }</td>                               
                        <td colspan="2">${ items[j].subtotal }</td>                               
                    </tr>`
        }
    }
    return item;
}

export const returnProClass = (txtLength)=>{
    let proClass = 'proName10'
    if (txtLength > 23 && txtLength <= 25) {
        proClass = 'proName13'
    } else if (txtLength > 25 && txtLength <= 27) {
        proClass = 'proName12'
    } else if (txtLength > 27 && txtLength <= 29) {
        proClass = 'proName11'
    } else if (txtLength > 29 && txtLength <= 31) {
        proClass = 'proName10'
    } else if (txtLength > 31 && txtLength <= 33) {
        proClass = 'proName9'
    } else if (txtLength > 33) {
        proClass = 'proName8'
    }
    return proClass
}
