import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/statement/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/statement/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/statement/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/statement/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statement/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createStatementReceive(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statement/createStatementReceive', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createStatement(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statement/createStatement', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    export(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statement/export', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    saveRemark(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statement/saveRemark', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statement/searchOrder', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statement/searchProduct', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statement/changeStatus', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    batchChangeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statement/batchChangeStatus', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    del(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statement/del', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    computeStatementSubTotal(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/statement/computeStatementSubTotal', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
