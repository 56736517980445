import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    condition: {},
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateCondition(state,condition){
      state.condition = condition
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/offlinesalesorder/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/offlinesalesorder/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/offlinesalesorder/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/offlinesalesorder/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorder/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changestatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorder/changestatus', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    index(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorder/index', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    grossProfitRate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorder/grossProfitRate', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeDriver(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorder/changeDriver', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    computeCarNo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorder/computeCarNo', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    saveAttachments(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorder/saveAttachments', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    delSOItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorder/delSOItem', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    saveRemark(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorder/saveRemark', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    pushBI(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorder/pushBI', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    amountSubmit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorder/amountSubmit', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    isInside(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorder/isInside', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
