<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- :to="{ name: 'apps-fee-edit'}" -->
              <b-form-input
                  v-model="searchKey"
                  class="d-inline-block mr-1"
                  placeholder="搜索关键字..."
              />
              <b-button
                variant="primary"
                @click="addFee()"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(fee_id)="data">
            #{{ data.item.fee_id }}
        </template>

        <!-- Column: fee_order_type -->
        <template #cell(fee_order_type)="data">
            {{getCodeLabel('fee_order_type', data.item.fee_order_type)}}
        </template>

        <!-- Column: fee_type -->
        <template #cell(fee_type)="data">
            {{getCodeLabel('fee_type', data.item.fee_type)}}
        </template>


        <!-- Column: status -->
        <template #cell(status)="data">
              {{getCodeLabel('extracost_status', data.item.status)}}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-${getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- 添加时间 -->
        <template #cell(create_time)="data">
          {{toTime(data.item.create_time)}}
        </template>

        <!-- 添加人 -->
        <template #cell(creator)="data">
          {{getCodeLabel("user",data.item.creator)}}
        </template>

        <!-- 添加时间 -->
        <template #cell(update_time)="data">
          {{toTime(data.item.update_time)}}
        </template>

        <!-- 附件 -->
        <template #cell(attachments)="data">
<!--          <button @click="openFile(data.item.attachments)" class="btn btn-success">附件</button>-->

          <div v-if="data.item.attachments == null">
            <b-button variant="link">暂无附件</b-button>
          </div>
          <div v-else>
            <b-button @click="openFile(data.item.attachments)" variant="link">附件</b-button>
          </div>
        </template>


        <!-- Column: Actions -->

        <template #cell(actions)="data" #button-content>
        <!--单菜单- :to="{ name: 'feeEdit', query: { id: data.item.id } }" -->
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50" @click="editFee(data.item.id)">编辑</span>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>


    <!-- 添加对话框 -->
    <b-modal
        id="feeAddWindow"
        ok-title="确认"
        cancel-title="取消"
        centered
        size="lg"
        title="附加费窗口"
        ref="feeAddWindow"
        @ok="toChild"
    >
      <fee-edit ref="feeEdit" :item="item">

      </fee-edit>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, toRefs} from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, get_upload_preview} from '@core/utils/filter'
import feeUseList from './feeUseList'
import feeStore from './feeStore'
import feeEdit from './FeeEdit'
import Vue from "vue";
import {useToast} from "vue-toastification/composition";


export default {
  // item: 采购订单数据
  props: ["item"],
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    feeEdit,
    useToast,
  },
  created() {
    if (this.item != null) {
      this.relationalId = this.item.id
      // fee_order_type  种类
      this.feeOrderTypeID = this.item.fee_type_id
    }
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('fee/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    addFee() {
      this.$parent.$route.query.id = 0
      this.$refs['feeAddWindow'].show()
    },
    editFee(id) {
      this.$parent.$route.query.id = id
      this.$refs['feeAddWindow'].show()
    },
    openFile(filePath) {
      // 跳转按钮
      window.open(process.env.VUE_APP_OSS_URL + '/' + filePath)
    },
  },


  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('fee')) store.registerModule('fee', feeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('fee')) store.unregisterModule('fee')
    })

    const toChild = async function (bvModalEvt) {
      let judge
      // 调用子组件的save方法
      let v = await this.$refs.feeEdit.save()
      if (v) {
        this.$refs['feeAddWindow'].hide()
        // 刷新表格
        this.refetchData()
      }else {
        toast.error('页面数据保存失败,订单编号、金额不可以为空!')
      }
    }

    const {
      searchList,
      relationalId,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      feeOrderTypeID,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      // UI
    } = feeUseList()

    return {
      searchList,
      relationalId,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      feeOrderTypeID,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      get_upload_preview,
      // UI
      toTime,
      toDate,
      toChild,
    }
  },
}

export class purchaseOrderList {
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
