<template>
  <b-card
    class="fee-edit-wrapper"
  >
    <ValidationObserver ref="observer">
    <!-- form -->
    <b-form id="feeForm" class="edit-form mt-2">
      <b-row>
        <b-col md="11">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="附加费所属订单分类"
            label-for="fee_order_type"
            label-size="sm"
            class="mb-1"
          >
            <v-select
                id="fee_order_type"
                :options="getCodeOptions('fee_order_type')"
                :clearable="true"
                v-model="feeOrderTypeId"
                @input="changeSelectFeeOrderType($event)"
                class="select-size-sm"
                :disabled="feeOrderTYpeIdDisabled"
            />
          </b-form-group>
        </b-col>

        <b-col md="11">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部关联订单编号"
            label-for="relational_no"
            label-size="sm"
            class="mb-1"
          >
            <ValidationProvider rules="required" name="关联订单编号" #default="{ errors }">
              <b-form-input
                id="relational_no"
                size="sm"
                v-model="fee.relational_no"
                :state="errors.length > 0 ? false:null"
                :disabled="relationalIdDisabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>

        <b-col md="11">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="附加费种类"
            label-for="fee_type"
            label-size="sm"
            class="mb-1"
          >

            <v-select
                id="fee_type"
                :options="getCodeOptions('fee_type')"
                :clearable="true"
                v-model="feeTypeId"
                @input="changeSelectFeeType($event)"
                class="select-size-sm"
            />

          </b-form-group>
        </b-col>
        <b-col md="11">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="金额"
            label-for="amount"
            label-size="sm"
            class="mb-1"
          >
            <ValidationProvider rules="required" name="金额" #default="{ errors }">
              <b-form-input
                id="amount"
                size="sm"
                type="number"
                v-model="fee.amount"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
 <!--   <b-col md="9">
                 <b-form-group
                   label-cols="2"
                   label-cols-lg="2"
                   label="状态"
                   label-for="status"
                   label-size="sm"
                   class="mb-1"
                 >

                   <v-select
                       id="status"
                       :options="getCodeOptions('extracost_status')"
                       :clearable="true"
                       v-model="extracoastStatusId"
                       @input="changeSelectExtracoastStatus($event)"
                       class="select-size-sm"
                   />

                 </b-form-group>
               </b-col>
               <b-col md="3">
                 <b-form-group
                   label-cols="3"
                   label-cols-lg="3"
                   label="启用"
                   label-for="state"
                   label-size="sm"
                   class="mb-1"
                 >
                   <b-form-input
                     id="state"
                     size="sm"
                     v-model="fee.state"
                   />
                 </b-form-group>
               </b-col>
               <b-col md="3">
                 <b-form-group
                     label-cols="3"
                     label-cols-lg="3"
                     label="添加人ID"
                     label-for="creator"
                     label-size="sm"
                     class="mb-1"
                 >
                   <b-form-input
                       id="creator"
                       size="sm"
                       v-model="fee.creator"
                   />
                 </b-form-group>
               </b-col>-->

        <b-col md="11">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="附件"
            label-for="attachments"
            label-size="sm"
            class="mb-1"
          >
            <attachment-upload
                               :attachment_id="'attachments'"
                               :id="fee.attachments"
                               :object_type="'fee'"
                               :object_id="0"
                               @change="onUploaded"
            />

          </b-form-group>
        </b-col>
<!--        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="fee.create_time"
            />
          </b-form-group>
        </b-col> -->

        <!--<b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新时间"
            label-for="update_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="update_time"
              size="sm"
              v-model="fee.update_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新人ID"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="fee.updator"
            />
          </b-form-group>
        </b-col>-->
                <b-col
          cols="12"
          class="mt-50"
        >
        <!-- 自动生成的保存按钮和取消按钮 -->
<!--      <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>-->
        </b-col>
      </b-row>
    </b-form>
    </ValidationObserver>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import feeStore from './feeStore'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  props: {
    item: {
      type: Object,
    },
  },
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      fee: ref({}),
      feeOrderTypeId: {},
      feeTypeId: {},
      feeRelationalId: '',
      extracoastStatusId: {},
      feeOrderTYpeIdDisabled: false,
      relationalIdDisabled: false,
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('fee')) store.registerModule('fee', feeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('fee')) store.unregisterModule('fee')
    })

    const edit = function() {
      store.dispatch('fee/edit', {id: this.id}).then(res => {
        this.fee = res.data.data
        if (!res.data.data.new) {
          this.feeOrderTYpeIdDisabled = true
          this.relationalIdDisabled = true
        }
        // 数据回显
        let values = getCodeOptions('fee_type')
        for (let i = 0; i < values.length; i++) {
          if (values[i].value == this.fee.fee_type) {
            this.feeTypeId = values[i].label
          }
        }
        let feeOrderTypeValues = getCodeOptions('fee_order_type')
        for (let i = 0; i < feeOrderTypeValues.length; i++) {
          if (feeOrderTypeValues[i].value == this.fee.fee_order_type) {
            this.feeOrderTypeId = feeOrderTypeValues[i].label
          }
        }

        if (this.item != null) {
          this.feeOrderTYpeIdDisabled = true
          this.relationalIdDisabled = true
          let feeOrderTypeValues = getCodeOptions('fee_order_type')
          for (let i = 0; i < feeOrderTypeValues.length; i++) {
            if (feeOrderTypeValues[i].value == this.item.fee_type_id) {
              this.feeOrderTypeId = feeOrderTypeValues[i].label
            }
          }
          // stock_id、 stock_id
          this.fee.fee_order_type = this.item.fee_order_type
          this.fee.relational_no = this.item.relational_no
          this.fee.relational_id = this.item.relational_id
          this.fee.fee_type = this.item.fee_type
        }
      })
    }

    const view = function() {
      store.dispatch('fee/view', {id: this.id}).then(res => {
        this.fee = res.data.data
      })
    }

    // 下拉菜单选项卡
    const changeSelectFeeOrderType = function (event) {
      this.fee.fee_order_type = event.value
    }

    const changeSelectFeeType = function (event) {
      this.fee.fee_type = event.value
    }

    const changeSelectExtracoastStatus = function (event) {
      this.fee.status = event.value
    }

    const cancel = function() {

    }

    const save = async function() {
      //主页面字段校验
      let validate = await this.$refs.observer.validate()
      if (validate) {
        if (this.item != null) {
          this.fee.fee_order_type = this.item.fee_type_id
          if (this.item.fee_type_id === 1) {
            // 采购订单 item 关联id赋值
            this.fee.relational_id = this.item.order_id

          } else if (this.item.fee_type_id === 2) {
            // 销售订单 item 关联id赋值
            this.fee.relational_id = this.item.order_id

          } else if (this.item.fee_type_id === 3) {
            // 退货订单 item 关联id赋值
            this.fee.relational_id = this.item.returnbound_id

          } else if (this.item.fee_type_id === 4) {
            // 调拨订单 item 关联id赋值
            this.fee.relational_id = this.item.outbound_id

          }
        }
        store.dispatch('fee/save', this.fee)
        .then(res => {
          if (res.data.code === 0) {
            toast.success('数据已保存!')
          } else {
            toast.error('页面数据保存失败!', res.data.data)
          }
        })
        return true
      } else {
        return validate
      }
    }

    const onUploaded = function (id, attachment) {
      this.fee[id] = attachment.attachment_id
      this.fee.attachments = attachment.path
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      changeSelectFeeOrderType,
      changeSelectFeeType,
      changeSelectExtracoastStatus,
      onUploaded,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
