import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default function feeUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'fee_id', label: 'ID', sortable: true },
    { key: 'fee_order_type', label: '订单分类', sortable: true },
    //{ key: 'relational_id', label: '外部关联订单ID', sortable: true },
    { key: 'relational_no', label: '外部关联订单编号', sortable: true },
    { key: 'fee_type', label: '附加费种类', sortable: true },
    { key: 'amount', label: '金额(元)', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    // { key: 'state', label: '启用', sortable: true },
    { key: 'attachments', label: '附件'},
    { key: 'create_time', label: '添加时间', sortable: true },
    // { key: 'creator', label: '添加人ID', sortable: true },
    //{ key: 'update_time', label: '更新时间', sortable: true },
    //{ key: 'updator', label: '更新人ID', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const relationalId = ref(0)
  const feeOrderTypeID = ref(0)
  const orderBy = ref('fee_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey, relationalId], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('fee/search', {
        relational_id: relationalId.value,
        fee_order_type: feeOrderTypeID.value,
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '附加费列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    relationalId,
    feeOrderTypeID,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
