<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
            <b-alert
                variant="primary"
                show
            >
              <div class="alert-body">
                <span><strong>已勾选:{{
                    checked.length
                  }}  合计数量:{{ checked.reduce((sum, e) => sum + Number(e.total_qty || 0), 0) }}</strong> </span>
              </div>
            </b-alert>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品69码:"
                    label-for="productCode"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.productCode"
                      name="productCode"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="product_name"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.productName"
                      name="productName"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品ID:"
                    label-for="product_id"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.productId"
                      name="productId"
                      class="d-inline-block mr-1"
                      type="number"
                      size="sm"
                      placeholder="请输入商品ID"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订单编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.orderNo"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订单状态:"
                    label-for="orderStatus"
                    label-size="sm"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('contract_status')"
                      v-model="state.condition.orderStatus"
                      name="orderStatus"
                      class="select-size-sm"
                      placeholder="请选择订单状态"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订单来源:"
                    label-for="source"
                    label-size="sm"
                >
                  <v-select
                      id="source"
                      :options="getCodeOptions('sales_order_source')"
                      v-model="state.condition.source"
                      name="source"
                      class="select-size-sm"
                      placeholder="请选择订单来源"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="已发货数量是否为0:"
                    label-for="qtyIsZero"
                    label-size="sm"
                >
                  <v-select
                      id="qtyIsZero"
                      :options="getCodeOptions('yesno')"
                      v-model="state.condition.qtyIsZero"
                      name="qtyIsZero"
                      class="select-size-sm"
                      placeholder="请选择已发货数量是否为0"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="是否生成对账单:"
                    label-for="isStatement"
                    label-size="sm"
                >
                  <v-select
                      id="isStatement"
                      :options="getCodeOptions('yesno')"
                      v-model="state.condition.isStatement"
                      name="isStatement"
                      class="select-size-sm"
                      placeholder="是否生成对账单"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="是否关联前置单:"
                        label-for="isRelationPreSalesOrder"
                        label-size="sm"

                >
                  <v-select
                          id="isRelationPreSalesOrder"
                          :options="getCodeOptions('yesno')"
                          v-model="state.condition.isRelationPreSalesOrder"
                          name="isRelationPreSalesOrder"
                          class="select-size-sm"
                          placeholder="是否关联前置单"
                  />
                </b-form-group>
              </b-col>


              <b-col
                      cols="12"
                      md="4"
                      v-show="userIsSupperOrSettle"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="是否发送BI:"
                        label-for="isReceiptBi"
                        label-size="sm"
                >
                  <v-select
                          id="isReceiptBi"
                          :options="getCodeOptions('yesno')"
                          :clearable="true"
                          v-model="state.condition.isSendbiStr"
                          name="moneyIsLowZero"
                          class="select-size-sm"
                          placeholder="请选择是否发送BI"
                  />
                </b-form-group>
              </b-col>
              <b-col
                      cols="12"
                      md="4"
                      v-show="userIsSupperOrSettle"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="是否负毛利订单:"
                        label-for="moneyIsLowZero"
                        label-size="sm"
                >
                  <v-select
                          id="moneyIsLowZero"
                          :options="moneyIsLowZeroOptions"
                          :clearable="true"
                          v-model="state.condition.moneyIsLowZero"
                          name="moneyIsLowZero"
                          class="select-size-sm"
                          placeholder="请选择是否负毛利"
                  />
                </b-form-group>
              </b-col>


              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="装车编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.carNo"
                      name="carNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="司机姓名:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.carDriver"
                      name="carDriver"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订货编号:"
                    label-for="pre_order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.preOrderNo"
                      name="pre_order_no"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4" cols="12" class="mb-2">
                <xy-input-button label="创建日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['addTime'])"
                                 :params="['addTime']"
                                 :value="state.condition.addTime"
                >
                </xy-input-button>
              </b-col>
              <b-col md="4" cols="12">
                <xy-input-button label="销售日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['salesTime'])"
                                 :params="['salesTime']"
                                 :value="state.condition.salesTime"
                >
                </xy-input-button>
              </b-col>
              <b-col md="4" cols="12">
                <xy-input-button label="配送日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['deliveryTime'])"
                                 :params="['deliveryTime']"
                                 :value="state.condition.deliveryTime"
                >
                </xy-input-button>
              </b-col>

              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                    label="终端客户"
                    type="input"
                    v-on:change="fromChildren($event,['channelName','channelId'])"
                    :params="['channelName','channelId']"
                    :value="state.condition.channelName"
                    modalName="终端客户"
                    placeholder="点击选择终端客户"
                >
                </xy-input-button>
              </b-col>
<!--              <b-col-->
<!--                  cols="12"-->
<!--                  md="4"-->
<!--              >-->
<!--                <xy-input-button-->
<!--                    label="销售主体"-->
<!--                    type="input"-->
<!--                    v-on:change="fromChildren($event,['companyName','companyId'])"-->
<!--                    :params="['companyName','companyId']"-->
<!--                    :value="state.condition.companyName"-->
<!--                    modalName="销售主体"-->
<!--                    placeholder="点击选择销售主体"-->
<!--                >-->
<!--                </xy-input-button>-->
<!--              </b-col>-->
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="团队名称"
                    type="input"
                    v-on:change="fromChildren($event,['storeName','storeid'])"
                    :params="['storeName','storeid']"
                    :value="state.condition.storeName"
                    modalName="店铺"
                    placeholder="点击选择团队名称"
                >
                </xy-input-button>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <xy-input-button
                    label="销售员"
                    type="input"
                    v-on:change="fromChildren($event,['salesName','salesId'])"
                    :params="['salesName','salesId']"
                    :value="state.condition.salesName"
                    modalName="销售员"
                    placeholder="点击选择销售员"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仓库"
                    label-for="user_level"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      v-model="state.condition.warehouseArray"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="label"
                      :options="warehouseArray"
                      class="select-size-sm"
                      :close-on-select="false"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="销售权:"
                    label-for="status"
                    label-size="sm"
                >
                  <v-select
                      :options="getCodeOptions('purchase_team')"
                      v-model="state.condition.purchaseTeamId"
                      name="status"
                      class="select-size-sm"
                      placeholder="请选择销售权"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="showdriverExportsModal"
                >
                  <span class="align-right">打印装车单</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="delivery"
                >
                  <span class="align-right">打印送货单</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="amountSubmit"
                        v-if="userIsSupperOrSettle"
                >
                  <!--功能暂未开放-->
                  <span class="align-right">批量终审</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="Export"
                >
                  <span class="align-right">导出</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td :colspan="6" variant="primary">
              <span><strong>合计</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{totalQty==0?'':totalQty}}</strong></span>
            </b-td>
            <b-td :colspan="tableColumns.length" variant="primary">
              <span><strong>{{totalReceive==0?'':totalReceive}}</strong></span>
            </b-td>
          </b-tr>
        </template>

<!--        <b-alert-->
<!--            variant="primary"-->
<!--            show-->
<!--        >-->
<!--          <div class="alert-body">-->
<!--            <b-row>-->
<!--              <b-col md="6">-->
<!--                <span><strong>合计</strong> </span>-->
<!--              </b-col>-->
<!--              <b-col md="2">-->
<!--                <span><strong>销售总数量:{{ totalQty }}</strong> </span>-->
<!--              </b-col>-->
<!--              <b-col md="2">-->
<!--                <span><strong>销售总金额:{{ totalReceive }}</strong> </span>-->
<!--              </b-col>-->
<!--            </b-row>-->

<!--          </div>-->
<!--        </b-alert>-->

        <template v-slot:head(#)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>
        <template v-slot:cell(#)="row">
          <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <main-table-item-list :is_sendbi="row.item.is_sendbi===1" :order_id="row.item.order_id"></main-table-item-list>
        </template>

        <!-- Column: Type -->
        <template #cell(order_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
<!--          [#{{ data.item.id }}]-->
          {{ data.item.order_no }}
          <br>
          {{isEmpty(data.item.ext.preOrderNo)?'':data.item.ext.preOrderNo}}

        </template>

        <template #cell(sendbi_time)="data">
          {{ toTime(data.item.sendbi_time) }}
        </template>

        <template #cell(sendbier)="data">
          {{ getCodeLabel('user', data.item.sendbier) }}
        </template>

        <template #cell(is_sendbi)="data">
          <feather-icon
                  icon="CheckIcon"
                  size="21"
                  v-if="data.item.is_sendbi==1"
          />
          <feather-icon
                  icon="XIcon"
                  size="21"
                  v-else
          />
        </template>




        <!--销售日期-->
        <template #cell(sales_time)="data">
          {{ toDate(data.item.sales_time) }}
        </template>

        <template #cell(delivery_time)="data">
          {{ toDate(data.item.delivery_time) }}
        </template>

        <template #cell(add_time)="data">
          创建时间:{{ toTime(data.item.add_time) }}
          <br>
          创建人:{{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(modify_time)="data">
          修改时间:{{ toTime(data.item.modify_time) }}
          <br>
          修改人:{{ getCodeLabel('user', data.item.updator) }}
        </template>

        <template #cell(sales_warehouse)="data">
          {{ getCodeLabel('warehouse', data.item.sales_warehouse) }}
        </template>

        <!--附加费-->
        <template #cell(extra_cost_subtotal)="data">
          <div :id="`extra_cost_subtotal-row-${data.item.id}`">
            {{ data.value }}
            <span v-if="data.value > 1000000"> ...</span>
            <feather-icon icon="EditIcon" @click="showFee(data.item)"/>
          </div>
        </template>

        <template #cell(is_statement)="data">
          <feather-icon
              icon="XIcon"
              size="21"
              v-if="data.item.is_statement==0||data.item.is_statement==undefined"
          />
          <feather-icon
              icon="CheckIcon"
              size="21"
              v-else
          />
        </template>

        <template #cell(pre_order_id)="data">
          <feather-icon
                  icon="CheckIcon"
                  size="21"
                  v-if="data.item.pre_order_id>0"
          />
          <feather-icon
                  icon="XIcon"
                  size="21"
                  v-else
          />
        </template>

        <template #cell(total_receive)="data">
          {{ data.item.total_receive || 0 }}
        </template>

        <template #cell(grossProfit)="data">
          {{ data.item.ext.grossProfit || 0 }}
        </template>

        <template #cell(salesCostAll)="data">
          {{ isNumber(data.item.ext.salesCostAll )}}
        </template>

        <template #cell(total_qty)="data">
          {{ data.item.total_qty || 0 }}
        </template>

        <template #cell(total_delivery_qty)="data">
          {{ data.item.total_delivery_qty || 0 }}
        </template>

        <template #cell(salesorder_type)="data">
          {{ getCodeLabel('salesorder_type', data.item.salesorder_type) }}
        </template>

        <template #cell(type_id)="data">
          {{ getCodeLabel('business_type', data.item.type_id) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <!--        <template #cell(add_time)="data">-->
        <!--          创建时间:{{ toTime(data.item.add_time) }}-->
        <!--          <br>-->
        <!--          创建人:{{ getCodeLabel('user', data.item.creator) }}-->
        <!--        </template>-->

        <template #cell(modify_time)="data">
          修改时间:{{ toTime(data.item.modify_time) }}
          <br>
          修改人:{{ getCodeLabel('user', data.item.updator) }}
        </template>

        <template #cell(source)="data">
          {{ getCodeLabel('sales_order_source', data.item.source) }}
        </template>

        <template #cell(remark)="data">
          <div :id="`remark-row-${data.item.id}`">
            <feather-icon icon="EditIcon" @click="showRemark(data.item)"/>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.id}`"
              placement="top"
          >
            {{ data.item.remark }}
          </b-tooltip>
        </template>

        <template #cell(pre_remark)="data">
          <div :id="`pre-remark-row-${data.item.id}`">
            <feather-icon icon="MessageCircleIcon" />
          </div>
          <b-tooltip
                  :target="`pre-remark-row-${data.item.id}`"
                  placement="top"
          >

            {{ data.item.ext.pre_remark }}
          </b-tooltip>
        </template>

        <template #cell(clerk_memo)="data">
          <div :id="`clerk_memo-row-${data.item.id}`">
            <feather-icon icon="MessageCircleIcon" />
          </div>
          <b-tooltip
                  :target="`clerk_memo-row-${data.item.id}`"
                  placement="top"
          >

            {{ data.item.ext.clerk_memo }}
          </b-tooltip>
        </template>

        <template #cell(status)="data">
          <!--          <span :class="'text-'+getCode('contract_status', data.item.status).color">{{getCodeLabel('contract_status', data.item.status)}}</span>-->
          <b-badge
              pill
              :variant="`light-${getCodeColor('contract_status', data.item.status)}`"
          >
            {{ getCodeLabel("contract_status", data.item.status) }}
          </b-badge>
        </template>

        <template #cell(price_check)="data">
          <feather-icon
              icon="XIcon"
              size="21"
              v-if="data.item.price_check_status==undefined"
          />
          <b-link
              :to="{name: 'apps-pricecheck-list', query: { order_no: data.item.order_no}}"
              target="_blank"
          >
            <feather-icon
                icon="CheckIcon"
                size="21"
                v-if="data.item.price_check_status!=undefined"
            />
          </b-link>
        </template>

        <template #cell(price_check_status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('price_check_status', data.item.price_check_status)}`"
          >
            {{ getCodeLabel("price_check_status", data.item.price_check_status) }}
          </b-badge>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"
            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <template #cell(attach)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments'"
                             :id="data.item.attachments"
                             :object_type="'sales_order'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
        </template>

        <template #cell(pre_attach)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments'"
                             :id="data.item.ext.pre_attach"
                             :object_type="'pre_sales_order'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
        </template>

        <!-- 内部交易       -->
        <template #cell(is_inside)="data" v-if="user.role_id === 11 || user.role_id === 12 || user.role_id === 14">
          <b-link @click="isInside(data.item)">
            <b-badge
                pill
                :variant="`light-${getCodeColor('yesno', data.item.is_inside==null?0:data.item.is_inside)}`"
            >
              {{ getCodeLabel('yesno', data.item.is_inside==null?0:data.item.is_inside) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
                :to="{ name: 'apps-offlinesalesorder-edit', query: { id: data.item.id},params:{readonly:1} }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">查看销售单</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status==0 && data.item.price_check_status!==1"
                             :to="{ name: 'apps-offlinesalesorder-edit', query: { id: data.item.id },params:{readonly:0} }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑销售单</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,0,'驳回')"
                             v-if="(data.item.status==1&&data.item.pre_order_id===undefined)||(data.item.status==2&&(data.item.total_delivery_qty==0||data.item.total_delivery_qty==null))">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">驳回{{data.item.pre_order_id}}</span>
            </b-dropdown-item>


            <div>
              <b-dropdown-item @click="changeStatus(data.item,1,'提交审核')" v-if="data.item.status == 0">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">提交审核</span>
              </b-dropdown-item>

              <b-dropdown-item @click="changeStatus(data.item,7,'作废')" v-if="data.item.status == 0">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">作废</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="data.item.status == 2&&user.role_id==14"
                               :to="{ name: 'apps-salesoutbound-edit', query: { orderid: data.item.order_id } }">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">创建流程出库通知单</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="data.item.status === 2" v-b-modal.modal-after-sales-select>
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">售后单选择</span>
              </b-dropdown-item>
            </div>

<!--            <b-dropdown-item @click="createStatement(data.item.order_id)" v-if="data.item.is_statement != 1&&data.item.status==2">-->
<!--              <feather-icon icon="FileTextIcon"/>-->
<!--              <span class="align-middle ml-50">生成对账单</span>-->
<!--            </b-dropdown-item>-->

            <b-dropdown-item  @click="attachWh(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">附件上传</span>
            </b-dropdown-item>


            <!--            仓库-->
            <!--            <div-->
            <!--                v-if="user.role_id==9||user.role_id==10">-->
            <!--              <b-dropdown-item v-if="data.item.status==0||user.user_id==20"-->
            <!--                               :to="{ name: 'apps-offlinesalesorder-edit', query: { id: data.item.id },params:{readonly:0} }">-->
            <!--                <feather-icon icon="EditIcon"/>-->
            <!--                <span class="align-middle ml-50">编辑销售单</span>-->
            <!--              </b-dropdown-item>-->

            <!--              <b-dropdown-item @click="changeStatus(data.item,1,'提交审核')" v-if="data.item.status == 0">-->
            <!--                <feather-icon icon="FileTextIcon"/>-->
            <!--                <span class="align-middle ml-50">提交审核</span>-->
            <!--              </b-dropdown-item>-->

            <!--              <b-dropdown-item @click="changeStatus(data.item,0,'驳回')"-->
            <!--                               v-if="data.item.status==1||(data.item.status==2&&(data.item.total_delivery_qty==0||data.item.total_delivery_qty==null))">-->
            <!--                <feather-icon icon="EditIcon"/>-->
            <!--                <span class="align-middle ml-50">驳回</span>-->
            <!--              </b-dropdown-item>-->

            <!--              <b-dropdown-item @click="changeStatus(data.item,2,'审核通过')" v-if="data.item.status==1">-->
            <!--                <feather-icon icon="EditIcon"/>-->
            <!--                <span class="align-middle ml-50">审核通过</span>-->
            <!--              </b-dropdown-item>-->

            <!--              <b-dropdown-item @click="createAutomatic(data)" v-if="data.item.status == 2" v-b-modal.modal-select>-->
            <!--                <feather-icon icon="FileTextIcon"/>-->
            <!--                <span class="align-middle ml-50">创建自动出库通知单</span>-->
            <!--              </b-dropdown-item>-->
            <!--            </div>-->


            <div v-if="user.role_id==14 ||user.role_id==27 || user.role_id==4|| user.role_id==6|| user.role_id==18|| user.role_id==10|| user.role_id==16|| user.role_id==12">
              <b-dropdown-item @click="changeStatus(data.item,2,'审核通过')" v-if="data.item.status==1&&(data.item.pre_order_id===undefined)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">审核通过{{data.item.pre_order_id}}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="createAutomatic(data)" v-if="data.item.status == 2" v-b-modal.modal-select>
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">创建自动出库通知单</span>
              </b-dropdown-item>

              <b-dropdown-item @click="showdriverModal(data.item)" v-if="data.item.car_no != ''">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">修改司机</span>
              </b-dropdown-item>
            </div>

            <div v-if="userIsSupperOrSettle&&data.item.status ===2">
              <b-dropdown-item @click="pushBI(data.item)" v-if="data.item.is_sendbi===undefined||data.item.is_sendbi===null">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">终审通过</span>
              </b-dropdown-item>
            </div>

            <!--销售单押单   oa审核通过状态-->
            <b-dropdown-item @click="createDeposit(data.item.order_id)" v-if="data.item.status == 2&&isNumber(data.item.is_statement)==0">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">标为押单</span>
            </b-dropdown-item>

          </b-dropdown>

        </template>

      </b-table>

      <b-modal
          id="modal-after-sales-select"
          ok-only
          ok-title="确认"
          @ok="onSelect"
          @close="close"
          cancel-title="取消"
          centered
          size="sm"
          title="店铺选择"
          v-if="a"
      >
        <v-select
            id="afterSalesSelect"
            :options="storeArray"
            v-model="store"
            :clearable="true"
            class="select-size-sm"
            @input="storeInput($event)"
            disabled="true"
        />
      </b-modal>

      <b-modal
          id="modal-select"
          ok-only
          ok-title="确认"
          @ok="onSelect"
          @close="close"
          cancel-title="取消"
          centered
          size="sm"
          title="店铺选择"
          v-if="a"
      >
        <v-select
            id="other_company_name"
            :options="storeArray"
            v-model="store"
            :clearable="true"
            class="select-size-sm"
            @input="storeInput($event)"
            disabled="true"
        />
      </b-modal>

      <b-modal
          id="modal-remark"
          ok-only
          ok-title="确认"
          @ok="saveRemark"
          cancel-title="取消"
          centered
          size="lg"
          title="修改备注"
          ref="remarkModal"
      >
        <b-card :header="`编号：${selectedItem.order_no}`">
          <b-form-textarea
              id="textarea"
              v-model="remarkInfo"
              rows="3"
              max-rows="6"
          />
        </b-card>
      </b-modal>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>

        <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
        >

          <company-select
              ref="companySelect" :companyType="companyType" v-if="modalName === '销售主体' || modalName ==='渠道方' "
          >
          </company-select>

          <!-- :userDepartment="userDepartment"  判断负责人所属部门-->
          <user-select
              ref="userSelect" :userDepartment="userDepartment" v-else-if="modalName === '销售员' "
          >
          </user-select>

          <store-list
              ref="storeList" v-else-if="modalName === '店铺' "
          >
          </store-list>

        </b-modal>
      </div>
    </b-card>

    <!-- 附加费弹出窗口 -->
    <b-modal
        id="feeModal"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="xl"
        title="附加费列表"
        ref="feeModal"
        @hidden="resetModal"
    >
      <fee-list
          ref="FeeList" :item="item">
      </fee-list>
    </b-modal>

    <!-- 司机弹出窗口 -->
    <b-modal
        id="driverModal"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="sm"
        :title="sign=='yes'?'修改司机':'填写司机'"
        ref="driverModal"
        @ok="exports"
        @close="cleanCarDriver"
        no-close-on-backdrop
        no-close-on-esc
    >
      <b-form-group
          label-cols="3"
          label-cols-lg="3"
          label="装车编号"
          label-for="channel_order_no"
          label-size="sm"
          class="mb-1"
          v-if="sign=='yes'"
      >
        <b-form-input
            size="sm"
            v-model="carNo"
        />
      </b-form-group>
      <b-form-group
          label-cols="3"
          label-cols-lg="3"
          label="司机姓名"
          label-for="channel_order_no"
          label-size="sm"
          class="mb-1 required"
      >
        <b-form-input
            size="sm"
            v-model="driverName"
        />
      </b-form-group>
    </b-modal>
    <!-- 加载modal -->
    <b-modal
        id="loadingModal"
        centered
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        ref="loadingModal"
    >
      <p class="my-4">单据创建中，请勿关闭浏览器!</p>
    </b-modal>


    <b-modal
        id="attachModal"
        centered
        title="附件上传"
        ref="attachModal"
        @ok="saveAttachWh"
        cancel-title="取消"

    >
      <attachment-upload :theme="'files'"
                         :attachment_id="'attachments'"
                         :id="attachmentsId"
                         :object_type="'sales_order'"
                         :object_id="attachSoId"
                         @change="attachWhLoaded"
      />
    </b-modal>

  </div>
</template>


<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, onMounted, reactive, toRef, toRefs} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty,
  lodopWatermark,isNumber
} from '@core/utils/filter'
import salesorderUseList from './salesorderUseList'
import salesorderStore from '@/views/apps/offlinesalesorder/salesorderStore'
import storeStore from '@/views/apps/store/storeStore'
import salesoutboundStore from '@/views/apps/salesoutbound/salesoutboundStore'
import Button from "@/views/components/button/Button";
import Ripple from "vue-ripple-directive";
import {useToast} from 'vue-toastification/composition'
import FeeList from "@/views/apps/fee/FeeList";
import {getUserData} from "@/auth/utils";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import CompanySelect from "../company/CompanySelect"
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import StoreList from "../store/StoreListSelect";
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from "../../../@core/components/toastification/ToastificationContent";
import XyInputButton from "@/views/components/xy/XyInputButton";
import offlinesalesorder from "@/router/routes/apps/offlinesalesorder";
import {getLodop} from "@/libs/LodopFuncs"
import axios from "@/libs/axios";
import operationlimitStore from "@/views/apps/operationlimit/operationlimitStore";
import MainTableItemList from "@/views/apps/offlinesalesorderitem/MainTableItemList";
import statementStore from "@/views/apps/statement/statementStore";
import lodopStore from "@/views/apps/lodop/lodopStore";
import {useRouter} from "@core/utils/utils";
import warehouseStore from "@/views/apps/warehouse/warehouseStore";
import salesorderdepositStore from "@/views/apps/salesorderdeposit/salesorderdepositStore";


export default {
  components: {
    MainTableItemList,
    XyInputButton,
    CompanySelect,
    UserSelect,
    StoreList,
    Button,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    FeeList,
    AttachmentUpload,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      a: false,
      storeArray: [],
      store: {},
      id: 0,
      selectedItem: {},
      storecompanyid: 0,
      remarkInfo: null,
      //选中行的数据
      item: {},
      fields: [
        {key: 'ext', label: '商品69码'},
        {key: 'name', label: '名称'},
        {key: 'warehouse_name', label: '仓库'},
        {key: 'status', label: '库存状态'},
        {key: 'is_gift', label: '赠品'},
        {key: 'product_cost', label: '售价 '},
        {key: 'product_cost_tax', label: '含税售价'},
        {key: 'tax_rate', label: '税率%'},
        {key: 'tax_subtotal', label: '税额 '},
        {key: 'cost', label: '金额'},
        {key: 'subtotal', label: '价税合计 '},
        {key: 'qty', label: '售卖数量'},
        {key: 'salesreturnqty', label: '退货数量'},
        {key: 'cost_price', label: '成本价'},
        {key: 'gross_profit_rate', label: '毛利率 '},
      ],
      user: {},
      advanced_search_modal: false,
      userDepartment: "sales",
      companyType: '',
      modalName: '',
      channelName: '',
      salesName: '',
      companyName: '',
      storeName: '',
      storeId: 0,

      checked: [],
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      driverName: "",
      carNo: "",
      sign: "",
      soId: 0,
      attachSoId:'',
      attachmentsId:'',
      attachmentsWhId:'',

      userIsSupperOrSettle:false,
      moneyIsLowZeroOptions:[],
      warehouseArray:[]
    }
  },
  watch: {
    start:'refetchData',
    limit:'refetchData'
  },
  methods: {
    printPreview(s) {
      var LODOP = getLodop()
      this.CreateOneFormPage()
      if (s == 0) {
        LODOP.PRINT() //直接打印
      }
      if (s == 1) {
        LODOP.PREVIEW() //打印预览
      }
      if (s == 2) {
        LODOP.PRINT_SETUP() //打印维护
      }
      if (s == 3) {
        LODOP.PRINT_DESIGN() //打印设计
      }
    },
    CreateOneFormPage() {
      var LODOP = getLodop()
      //样式
      var olstyle1 = '<style>' + document.getElementById('olstyle1') + '</style>'
      var body = olstyle1 + '<body>' + document.getElementById('form1').innerHTML + '</body>'
      LODOP.PRINT_INIT('订单') //打印初始化
      LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
      LODOP.SET_PRINT_STYLE('Bold', 1) //设置对象风格
      LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
      LODOP.SET_PRINT_PAGESIZE(0, 2000, 2000, '') //设定纸张大小
      LODOP.SET_PRINT_MODE('PRINT_PAGE_PERCENT', '55%')//设置缩放
      LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口
      // 打印二维码
      // LODOP.ADD_PRINT_BARCODE(23,23,233,233,"QRCode","https://blog.csdn.net/qq_43652509");
      //打印网址
      // LODOP.ADD_PRINT_URL(222,2000,2000,233,"https://blog.csdn.net/qq_43652509");
      //打印图片
      // LODOP.ADD_PRINT_IMAGE(100,100,400,400,"<img border='0' src='http://s1.sinaimg.cn/middle/4fe4ba17hb5afe2caa990&690' width='345' height='250'>");
      LODOP.ADD_PRINT_HTM(88, 20, 2000, 2000, body) //增加超文本项
    },
    // 清空选择框
    clearCheckBox() {
      this.table.forEach(element => {
        this.isCheckedObj['index' + element.id] = false;
      });
      this.allchecked = false;
      this.checked = []
      this.$forceUpdate()
    },
    refetchData(){
      this.$refs.refListTable.refresh()
      this.clearCheckBox()
    },
    // 点击全选按钮
    toggleAll(checked) {
      this.checked = []
      this.table.forEach(element => {
        if (checked) {
          this.checked.push(element);
        }
        this.isCheckedObj['index' + element.id] = checked;
      });
      this.$forceUpdate()
    },
    // 复选框点击选中与取消
    checkboxClick(id) {
      let index = this.checked.indexOf(id);
      if (index > -1) {
        this.checked.splice(index, 1);
      } else {
        this.checked.push(id);
      }
      let allselect = true;
      this.table.forEach(element => {
        if (this.isCheckedObj['index' + element.id] !== true) {
          allselect = false;
        }
      });
      this.allchecked = allselect;
      this.$forceUpdate()
    },
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('offlinesalesorder/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    showRemark(item) {
      this.selectedItem = item
      this.remarkInfo = item.remark
      this.$refs['remarkModal'].show()
    },
    showFee(item) {
      this.item = item
      this.item.fee_type_id = 2
      this.item.relational_id = item.ext.salesOrderItem[0].order_id
      this.item.relational_no = item.ext.salesOrderItem[0].order_no
      this.$refs['feeModal'].show()
    },
    resetModal() {
      this.$refs.refListTable.refresh()
    },

    showSingleModal(modalName) {
      if (modalName === '渠道方') {
        this.companyType = 'ChannelCustomer'
      } else if (modalName === '销售主体') {
        this.companyType = 'Group'
      }
      this.modalName = modalName
      this.$refs['singleModal'].show()
    },

    onSelectSingle() {
      let data = {}
      switch (this.modalName) {
        case '渠道方': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.state.condition.channelName = data[0].company_name;
            this.state.condition.channelId = data[0].company_id;
            this.$forceUpdate()
          }
          break
        }

        case '销售主体': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.state.condition.companyName = data[0].company_name;
            this.state.condition.companyId = data[0].company_id;
            this.$forceUpdate()
          }
          break
        }
        case '销售员': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.state.condition.salesName = data[0].full_name;
            this.state.condition.salesId = data[0].user_id;
            this.$forceUpdate()
          }
          break;
        }
        case '店铺': {
          data = this.$refs.storeList.getSelected()
          if (data.length > 0) {
            this.state.condition.storeName = data[0].store_name;
            this.state.condition.storeid = data[0].store_id;
            this.$forceUpdate()
          }
          break;
        }
        default: {
          break
        }
      }
    },
    searchByCondition() {
      this.refetchData()
      // this.clearCheckBox()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('offlinesalesorder/updateCondition', this.state.condition)
      this.refetchData();
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('offlinesalesorder')) store.registerModule('offlinesalesorder', salesorderStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('salesoutbound')) store.registerModule('salesoutbound', salesoutboundStore)
    if (!store.hasModule('operationlimit')) store.registerModule('operationlimit', operationlimitStore)
    if (!store.hasModule('statement')) store.registerModule('statement', statementStore)
    if (!store.hasModule('lodop')) store.registerModule('lodop', lodopStore)
    if (!store.hasModule('warehouse')) store.registerModule('warehouse', warehouseStore)
    if (!store.hasModule('salesorderdeposit')) store.registerModule('salesorderdeposit', salesorderdepositStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('offlinesalesorder')) store.unregisterModule('offlinesalesorder')
      if (store.hasModule('store')) store.unregisterModule('store')
      if (store.hasModule('salesoutbound')) store.unregisterModule('salesoutbound')
      if (store.hasModule('operationlimit')) store.unregisterModule('operationlimit')
      if (store.hasModule('statement')) store.unregisterModule('statement')
      if (store.hasModule('lodop')) store.unregisterModule('lodop')
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('salesorderdeposit')) store.unregisterModule('salesorderdeposit')
    })

    const state = reactive({
      condition: {},
    })

    onMounted(() => {
      state.condition = store.getters['offlinesalesorder/getCondition']
    })

    const changeStatus = function (params, status, information) {
      const grossProfitRateArray = []
      const nameArray = []
      if (status == 2) {
        // const itemArray = params.ext.salesOrderItem
        // for (let i = 0; i < itemArray.length; i++) {
        //   grossProfitRateArray.push(itemArray[i].ext.gross_profit_rate)
        //   nameArray.push(itemArray[i].name)
        // }
        // const grossProfitRate = grossProfitRateArray.join(",")
        // const name = nameArray.join(",")
        store.dispatch('offlinesalesorder/grossProfitRate', {
          id:params.order_id
        }).then(res => {
          if (res.data.code != 0) {
            if (confirm(res.data.data)) {
              changeStatusF(params, status,this)
            }
          } else {
            if (confirm("是否" + information)) {
              changeStatusF(params, status,this)
            }
          }
        })
      } else {
        if (confirm("是否" + information)) {
          changeStatusF(params, status,this)
        }
      }
    }

    const changeStatusF = function (params, status,_this) {
      if (status==2){
        _this.$refs['loadingModal'].show()
        //校验
        store.dispatch('operationlimit/osCheck', {
          type:1,
          orderId:params.order_id,
          checkOrDel:'check',
        }).then(response => {
          if (response.data.code == 0) {
            //出库
            store.dispatch('salesoutbound/autosave', {
              id: params.order_id,
              storeid: params.store_id,
            }).then(res => {
              if (res.data.code === 1) {
                toast.error(res.data.data)
                _this.$refs['loadingModal'].hide()
              }
              if (res.data.code === 0) {
                //改状态
                toast.success("审核成功")
                _this.refetchData()
                _this.$refs['loadingModal'].hide()
              }
              //删除
              store.dispatch('operationlimit/osCheck', {
                type:1,
                orderId:params.order_id,
                checkOrDel:'del',
              })
            })
          } else {
              toast.error(response.data.data)
            _this.$refs['loadingModal'].hide()
          }
        })
      }else {
        store.dispatch('offlinesalesorder/changestatus', {id: params.order_id, status: status}).then(res => {
          if (res.data.code == 0) {
            if (res.data.data.ext != undefined) {
              toast.error(res.data.data.ext.information)
              _this.refetchData()
            } else {
              toast.success("操作成功")
              _this.refetchData()
            }
          } else {
            toast.error(res.data.data)
            _this.refetchData()
          }
        })
      }
    }

    const createAutomatic = function (val) {
      var res = confirm("自动出库单创建完成后，自动扣除库存，确认操作吗？")
      if (res) {
        this.a = true
        this.id = val.item.order_id
        this.store = val.item.store_name
        this.storeId = val.item.store_id
      }
    }

    const onSelect = function () {
      if (this.storeId === 0) {
        alert("请选择店铺")
        return false
      }
      this.$refs['loadingModal'].show()
      store.dispatch('salesoutbound/autosave', {
        id: this.id,
        storeid: this.storeId,
        storename: this.store,
        storecompanyid: this.storecompanyid
      }).then(res => {
        if (res.data.code === 1) {
          toast.error(res.data.data)
        }
        if (res.data.code === 0) {
          toast.success("创建并出库完成")
          this.reload()
        }
        this.$refs['loadingModal'].hide()
      })
      this.a = false
    }

    const close = function () {
      this.a = false
    }

    const storeInput = function (event) {
      this.storeId = event.store_id
      this.store = event.label
      this.storecompanyid = event.store_company_id

    }

    const edit = function () {
      createStore(this.storeArray)
    }

    const createStore = function (d) {
      store.dispatch('store/search',).then(res => {
        // data.state = state
        let a = res.data.data.list
        for (let i = 0; i < a.length; i++) {
          d.push({"label": a[i].store_name, "store_id": a[i].store_id, "store_company_id": a[i].company_id})
        }
      })
    }


    const saveRemark = function () {
      this.selectedItem.remark = this.remarkInfo
      store.dispatch('offlinesalesorder/saveRemark', {id:this.selectedItem.order_id,remark:this.selectedItem.remark}).then(res => {
        if (res.data.code === 0) {
          toast.success("修改成功")
        } else {
          toast.error("修改失败")
        }
      })
    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.state.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const reload = function () {
      this.checked.forEach(element => {
        this.isCheckedObj['index' + element.id] = false;
      });
      this.checked.length = 0
      this.indeterminate = false
      this.allchecked = false
      this.refetchData()
    }

    const exports = function () {
      if (this.sign == "yes") {
        store.dispatch('offlinesalesorder/changeDriver', {
          carNo: this.carNo,
          carDriver: this.driverName,
          id: this.soId
        }).then(res => {
          if (res.data.code === 0) {
            toast.success("修改成功")
            this.reload()
          } else {
            toast.error(res.data.data)
          }
        })
        this.sign = ''
        this.soId = ''
        this.carNo = ''
        this.driverName = ''

      } else {
        if (this.driverName == "") {
          toast.error("请填写司机")
          return false
        }
        if (this.checked.length == 0) {
          toast.error("请选择销售单!")
          return false
        }
        const idArray = []
        for (let i = 0; i < this.checked.length; i++) {
          idArray.push(this.checked[i].order_id)
          if (this.checked[i].status==0){
            toast.error("草稿状态无法打印!")
            return false
          }
        }
        const ids = idArray.join(",")
        // window.open(process.env.VUE_APP_BASE_URL + '/api/offlinesalesorderexcel/createSalesOrderExcel?driverName=' + this.driverName + '&ids=' + ids)
        store.dispatch('lodop/createSalesOrderCarLODOP', {
          driverName: this.driverName,
          ids: ids,
        }).then(res => {
          if (res.data.code === 0) {
            var LODOP = getLodop();
            if (LODOP==undefined){
              toast.error("打印插件未安装，请安装插件!")
              return ;
            }
            LODOP.PRINT_INIT('打印装车单') //打印初始化
            //LODOP.SET_PRINTER_INDEX(printer) //选择的打印机类型（注意这里是打印机下标，0开始的）
            LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
            // this.LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
            LODOP.SET_PRINT_PAGESIZE(1, 2200, 1400, '') //设定纸张大小
            // LODOP.SET_PRINT_MODE('PRINT_PAGE_PERCENT', '55%')//设置缩放
            LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口

            const data = res.data.data.ext
            var item =  ``
            for (let i = 0; i < data.item.length; i++) {
              item+=`<tr height="23px">
                        <td align="center">${data.item[i].ext.no}</td>
                        <td align="left" style="font-size:12px;white-space: nowrap;">${data.item[i].name}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.item[i].ext.code}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.item[i].ext.productUnit}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.item[i].qty}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        </tr>`
            }

            var html = `
                <table width="740px" border="0" cellspacing="0" cellpadding="0">
                    <tr height="20px">
                        <td colspan="3">&nbsp;</td>
                    </tr>
                    <tr height="30px">
                        <td colspan="3" align="center"><font face="楷体" size="4">装车单</font></td>
                    </tr>
                    <tr height="25px">
                        <td width="250">装车编号:${data.car_no}</td>
                        <td width="240">制单人:${data.user}</td>
                        <td width="250">打印时间:${data.time}</td>
                    </tr>
                    <tr height="25px">
                        <td width="250">仓库:${data.warehouseName}</td>
                        <td width="240">仓管:</td>
                        <td width="250">审核人:</td>
                    </tr>
                    <tr height="25px">
                        <td width="250">司机:${data.car_driver}</td>
                    </tr>
                </table>

                <table width="740px" border="1" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td width="40px" align="center">序号</td>
                        <td width="370px" align="center">商品名称</td>
                        <td width="100px" align="center">69码</td>
                        <td width="50px" align="center">单位</td>
                        <td width="80px" align="center">数量</td>
                        <td width="60px" align="center">备注</td>
                    </tr>
                    ${item}
                    <tr height="23px">
                        <td align="center"></td>
                        <td align="left" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">合计</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.allQty}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                    </tr>
                </table>
          `
            lodopWatermark(LODOP)
            LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500, '<body>' + html + '</body>') // 增加超文本项（打印内容即为body内容）
            LODOP.PREVIEW() // 设置预览（如果使用预览，每次打印前会弹出预览窗口，根据需要决定是否使用）
            // LODOP.PRINT()
            this.driverName = ""
            this.reload()
          }else {
            toast.error(res.data.data)
          }
        })
        this.driverName = ""
        this.reload()
      }

    }

    const showdriverModal = function (params) {
      this.soId = params.order_id
      this.carNo = params.car_no
      this.driverName = params.car_driver
      this.sign = 'yes'
      this.$refs['driverModal'].show()
    }

    const cleanCarDriver = function (params) {
      this.sign = ''
      this.soId = ''
      this.carNo = ''
      this.driverName = ''
    }

    const showdriverExportsModal = function (params) {
      if (this.checked.length == 0) {
        toast.error("请选择销售单!")
        return false
      }
      const set = new Set()
      const setWarehouse = new Set()
      const idArray = []
      for (let i = 0; i < this.checked.length; i++) {
        idArray.push(this.checked[i].order_id)
        set.add(this.checked[i].car_no == null ? "" : this.checked[i].car_no)
        setWarehouse.add(this.checked[i].sales_warehouse)
        if (this.checked[i].status==0){
          toast.error("草稿状态无法打印!")
          return false
        }
      }
      if (set.size != 1) {
        toast.error("请选择相同装车编号的销售单!")
        return false
      }
      if (setWarehouse.size != 1) {
        toast.error("请选择相同仓库的销售单!")
        return false
      }
      for (let value of set.values()) {
        if (value == "") {
          this.$refs['driverModal'].show()
          return false
        }
      }
      const ids = idArray.join(",")
      store.dispatch('offlinesalesorder/computeCarNo', {
        carNo: this.checked[0].car_no,
        length: this.checked.length
      }).then(res => {
        if (res.data.code === 0) {
          // window.open(process.env.VUE_APP_BASE_URL + '/api/offlinesalesorderexcel/createSalesOrderExcel?is=true&driverName=' + this.driverName + '&ids=' + ids)
          store.dispatch('lodop/createSalesOrderCarLODOP', {
            is: true,
            driverName: this.driverName,
            ids: ids,
          }).then(res => {
            if (res.data.code === 0) {
              var LODOP = getLodop();
              if (LODOP==undefined){
                toast.error("打印插件未安装，请安装插件!")
                return ;
              }
              LODOP.PRINT_INIT('打印装车单') //打印初始化
              //LODOP.SET_PRINTER_INDEX(printer) //选择的打印机类型（注意这里是打印机下标，0开始的）
              LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
              // this.LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
              LODOP.SET_PRINT_PAGESIZE(1, 2200, 1400, '') //设定纸张大小
              // LODOP.SET_PRINT_MODE('PRINT_PAGE_PERCENT', '55%')//设置缩放
              LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口

              const data = res.data.data.ext
              var item =  ``
              for (let i = 0; i < data.item.length; i++) {
                item+=`<tr height="23px">
                        <td align="center">${data.item[i].ext.no}</td>
                        <td align="left" style="font-size:12px;white-space: nowrap;">${data.item[i].name}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.item[i].ext.code}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.item[i].ext.productUnit}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.item[i].qty}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        </tr>`
              }

              var html = `
                <table width="740px" border="0" cellspacing="0" cellpadding="0">
                    <tr height="20px">
                        <td colspan="3">&nbsp;</td>
                    </tr>
                    <tr height="30px">
                        <td colspan="3" align="center"><font face="楷体" size="4">装车单</font></td>
                    </tr>
                    <tr height="25px">
                        <td width="250">装车编号:${data.car_no}</td>
                        <td width="240">制单人:${data.user}</td>
                        <td width="250">打印时间:${data.time}</td>
                    </tr>
                    <tr height="25px">
                        <td width="250">仓库:${data.warehouseName}</td>
                        <td width="240">仓管:</td>
                        <td width="250">审核人:</td>
                    </tr>
                    <tr height="25px">
                        <td width="250">司机:${data.car_driver}</td>
                    </tr>
                </table>

                <table width="740px" border="1" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td width="40px" align="center">序号</td>
                        <td width="370px" align="center">商品名称</td>
                        <td width="100px" align="center">69码</td>
                        <td width="50px" align="center">单位</td>
                        <td width="80px" align="center">数量</td>
                        <td width="60px" align="center">备注</td>
                    </tr>
                    ${item}
                    <tr height="23px">
                        <td align="center"></td>
                        <td align="left" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">合计</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.allQty}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                    </tr>
                </table>
          `
              lodopWatermark(LODOP)
              LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500, '<body>' + html + '</body>') // 增加超文本项（打印内容即为body内容）
              LODOP.PREVIEW() // 设置预览（如果使用预览，每次打印前会弹出预览窗口，根据需要决定是否使用）
              // LODOP.PRINT()
              this.driverName = ""
              this.reload()
            }else {
              toast.error(res.data.data)
            }
          })
        } else {
          toast.error(res.data.data)
        }
      })
      this.driverName = ""
      this.reload()
    }

    const delivery = function () {
      if (this.checked.length == 0) {
        toast.error("请选择销售单!")
        return false
      }
      const set = new Set()
      const setWarehouse = new Set()
      const idArray = []
      for (let i = 0; i < this.checked.length; i++) {
        idArray.push(this.checked[i].order_id)
        set.add(this.checked[i].car_no == null ? "" : this.checked[i].car_no)
        setWarehouse.add(this.checked[i].sales_warehouse)
        if (this.checked[i].status==0){
          toast.error("草稿状态无法打印!")
          return false
        }
      }
      if (setWarehouse.size != 1) {
        toast.error("请选择相同仓库的销售单!")
        return false
      }
      const ids = idArray.join(",")
      store.dispatch('lodop/createSalesOrderLODOP', {
        is: true,
        ids: ids,
        warehouseId:this.checked[0].sales_warehouse,
      }).then(res => {
        if (res.data.code === 0) {
          const data = res.data.data
          var LODOP = getLodop();
          if (LODOP==undefined){
            toast.error("打印插件未安装，请安装插件!")
            return ;
          }
          LODOP.PRINT_INIT('打印送货单') //打印初始化
          //LODOP.SET_PRINTER_INDEX(printer) //选择的打印机类型（注意这里是打印机下标，0开始的）
          LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
          // this.LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
          if (data[0].ext.page==2){
            LODOP.SET_PRINT_PAGESIZE(1, 2200, 1400, '') //设定纸张大小
          }
          if (data[0].ext.page==3){
            LODOP.SET_PRINT_PAGESIZE(1, 2200, 930, '') //设定纸张大小
          }
          LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口
          var html = ''
          for (let i = 0; i < data.length; i++) {
            var item =  ``
            var items = data[i].ext.item
            for (let j = 0; j < items.length; j++) {
              item+=` <tr height="23px">
                        <td align="center">${items[j].ext.no}</td>
                        <td align="left" style="font-size:12px;white-space: nowrap;">${items[j].name}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${items[j].ext.code}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${items[j].product_cost_tax}/${items[j].ext.product_unit}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${items[j].qty}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${items[j].ext.price}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${items[j].memo==null?'':items[j].memo}</td>
                    </tr>`
            }

            html = `
                <table width="740px" border="0" cellspacing="0" cellpadding="0">
                    <tr height="20px">
                        <td colspan="3">&nbsp;</td>
                    </tr>
                    <tr height="30px">
                        <td colspan="3" align="center"><font face="楷体" size="4">${data[i].ext.warehouseHead==null?'':data[i].ext.warehouseHead}送货单(${data[i].ext.printTime}次)</font></td>
                    </tr>
                    <tr height="25px">
                        <td width="250">客户:${data[i].channel_name}</td>
                        <td width="240">客户电话:${data[i].ext.contact==null?'':data[i].ext.contact}</td>
                        <td width="250">销售编号:${data[i].order_no}</td>
                    </tr>
                    <tr height="25px">
                        <td width="250">地址:${data[i].ext.address==null?'':data[i].ext.address}</td>
                        <td width="240">制单人:${data[i].ext.user}</td>
                        <td width="250">打印日期:${data[i].ext.time}</td>
                    </tr>
                    <tr height="25px">
                        <td width="250">仓库:${data[i].ext.warehouse.warehouse_name}</td>
                        <td width="240">地址:${data[i].ext.warehouse.location==null?'':data[i].ext.warehouse.location}</td>
                        <td width="250">电话:${data[i].ext.warehouse.mobile}</td>
                    </tr>
                    <tr height="25px">
                        <td width="250">业务员：${data[i].sales_name}${data[i].sales_mobile==null?'':data[i].sales_mobile}</td>
                        <td width="240">司机：</td>
                        <td width="250">收货人：</td>
                    </tr>
                </table>
                <table width="740px" border="1" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td width="40px" align="center">序号</td>
                        <td width="300px" align="center">商品名称</td>
                        <td width="170px" align="center">69码</td>
                        <td width="50px" align="center">单价</td>
                        <td width="50px" align="center">数量</td>
                        <td width="70px" align="center">金额</td>
                        <td width="60px" align="center">备注</td>
                    </tr>
                    ${item}
                    <tr height="23px">
                        <td align="center"></td>
                        <td align="left" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">合计</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data[i].ext.allQtySheet}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data[i].ext.allPrice}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                    </tr>
                </table>
          `
            lodopWatermark(LODOP)
            LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500, '<body >' + html + '</body>') // 增加超文本项（打印内容即为body内容）
            if (data[i].ext.page==2){
              if (data[i].ext.item.length>14){
                LODOP.NewPage();
                const page = parseInt((data[i].ext.item.length-14) / 21)
                for (let j = 0; j < page; j++) {
                  LODOP.NewPage();
                }
              }
            }
            if(data[i].ext.page==3){
              if (data[i].ext.item.length>6){
                LODOP.NewPage();
                const page = parseInt((data[i].ext.item.length-6) / 14)
                for (let j = 0; j < page; j++) {
                  LODOP.NewPage();
                }
              }
            }
            // LODOP.SET_PRINT_STYLEA(i+1,"AngleOfPageInside",-90);
            LODOP.NewPage();

          }

          // LODOP.ADD_PRINT_HTM(88, 20, 1500, 1500, '<body>' + html + '</body>')
          LODOP.PREVIEW() // 设置预览（如果使用预览，每次打印前会弹出预览窗口，根据需要决定是否使用）
          // LODOP.PRINT()
          this.driverName = ""
          this.reload()
        }else {
          toast.error(res.data.data)
        }
      })
    }

    const Export = function () {
      const condition = state.condition
      const warehouseArray = []
      if (!isEmpty(condition.warehouseArray)){
        condition.warehouseArray.forEach((value, index, array) => {
          warehouseArray.push(value.value)
        })
      }
      axios({
            url: '/api/exportexcelfile/createOffSalesOrderExcel',
            method:'get',
            params: {
              channelId: condition.channelId,
              productCode: condition.productCode,
              productName: condition.productName,
              productId: condition.productId,
              salesId: condition.salesId,
              orderNo: condition.orderNo,
              companyId: condition.companyId,
              storeId: condition.storeid,
              warehouse: warehouseArray.join(','),
              orderStatus: condition.orderStatus != null ? condition.orderStatus.value : null,
              source: condition.source != null ? condition.source.value : null,
              qtyIsZero: condition.qtyIsZero != null ? condition.qtyIsZero.value : null,
              isStatement: condition.isStatement != null ? condition.isStatement.value : null,
              isRelationPreSalesOrder: condition.isRelationPreSalesOrder != null ? condition.isRelationPreSalesOrder.value : null,
              addTime: condition.addTime,
              deliveryTime: condition.deliveryTime,
              salesTime: condition.salesTime,
              carNo:condition.carNo,
              carDriver:condition.carDriver,
              moneyIsLowZero:condition.moneyIsLowZero != null ? condition.moneyIsLowZero.value : null,
              isSendbiStr:condition.isSendbiStr != null ? condition.isSendbiStr.value : null,
              preOrderNo: condition.preOrderNo,
              purchaseTeamId:isEmpty(condition.purchaseTeamId)?null:condition.purchaseTeamId.value,
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "线下销售单-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const attachWh = function (params) {
      this.attachSoId = params.id
      this.attachmentsId = params.attachments
      this.attachmentsWhId = ''
      this.$refs['attachModal'].show()
    }

    const saveAttachWh = function () {
      store.dispatch('offlinesalesorder/saveAttachments', {id: this.attachSoId,attachments:this.attachmentsWhId})
          .then(res => {
            if (res.data.code === 0) {
              toast.success(res.data.data)
              this.refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
    }

    const attachWhLoaded = function (id, attachment, result) {
      this.attachmentsWhId = result
    }

    const createStatement = function (params) {
      if (confirm("确定生成对账单吗?")){
        store.dispatch('statement/createStatement', {id: params})
            .then(res => {
              if (res.data.code === 0) {
                toast.success(res.data.data)
                this.refetchData()
              } else {
                toast.error(res.data.data)
              }
            })
      }
    }
    const pushBI = function (data) {
      store.dispatch('offlinesalesorder/pushBI', {ids:data.id}).then(res => {
        if (res.data.code === 0) {
          toast.success("推送成功")
          this.refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const router = useRouter()
    const amountSubmit = function(){
      //批量终审 chenc 20220620 start
      if (this.checked.length == 0) {
        toast.error("请选择销售单!")
        return false
      }
      const idArray = []
      for (let i = 0; i < this.checked.length; i++) {
        if(this.checked[i].status !== 2 || (this.checked[i].is_sendbi!==undefined && this.checked[i].is_sendbi!==null)){
          toast.error("选择的销售单中存在不符合条件的数据(状态非OA审批通过或已发送BI)!")
          return false
        }
        idArray.push(this.checked[i].order_id)
      }
      const ids = idArray.join(",")
      store.dispatch('offlinesalesorder/pushBI', {
        ids: ids,
      }).then(res => {
        if (res.data.code === 0) {
          toast.success('操作成功')
          this.refetchData()
        }else {
          toast.error(res.data.data)
        }
      })
      //批量终审 chenc 20220620 end
      //toast.error("功能暂未开放,超管可以看到此按钮！")
      // let condition = state.condition
      // let  params= {
      //           channelId: condition.channelId,
      //           productCode: condition.productCode,
      //           productName: condition.productName,
      //           productId: condition.productId,
      //           salesId: condition.salesId,
      //           orderNo: condition.orderNo,
      //           companyId: condition.companyId,
      //           storeId: condition.storeid,
      //           warehouse: condition.warehouseId,
      //           orderStatus: condition.orderStatus != null ? condition.orderStatus.value : null,
      //           source: condition.source != null ? condition.source.value : null,
      //           qtyIsZero: condition.qtyIsZero != null ? condition.qtyIsZero.value : null,
      //           isStatement: condition.isStatement != null ? condition.isStatement.value : null,
      //           isRelationPreSalesOrder: condition.isRelationPreSalesOrder != null ? condition.isRelationPreSalesOrder.value : null,
      //           addTime: condition.addTime,
      //           deliveryTime: condition.deliveryTime,
      //           salesTime: condition.salesTime,
      //           carNo:condition.carNo,
      //           carDriver:condition.carDriver,
      //           moneyIsLowZero:condition.moneyIsLowZero != null ? condition.moneyIsLowZero.value : null,
      //           isSendbiStr:condition.isSendbiStr != null ? condition.isSendbiStr.value : null,
      //           preOrderNo: condition.preOrderNo,
      //         }
      // store.dispatch('offlinesalesorder/amountSubmit', params).then(res => {
      //   // console.log(res.data.data.list)
      //   // console.log(res.data.data.errorData)
      //   if(res.data.code==0){
      //     let list = res.data.data.list;
      //     let errorData = res.data.data.errorData
      //     if ( !isEmpty(errorData)) {
      //       if (confirm(errorData)) {
      //         amountSubmitSuccess(list,this)
      //       }
      //     } else {
      //       if (confirm("是否全部审核通过")) {
      //         amountSubmitSuccess(list,this)
      //       }
      //     }
      //   }else{
      //     toast.error(res.data.data)
      //   }
      //
      // })

    }
    const amountSubmitSuccess = function (list,_this) {

      if (list.length > 0) {
        var promise = Promise.resolve();
        for (let i = 0; i < list.length; i++) {
          _this.$refs['loadingModal'].show()
          promise = promise.then(() => {
            return amountFunc(list[i], _this)
          })
        }
        promise.then(() => {
          this.refetchData()
          _this.$refs['loadingModal'].hide()
        })

      }
    }
    const amountFunc = function (data,_this) {
       return Promise.resolve(data)
               .then((res) => {
                 return new Promise((resolve, reject) => {
                   //校验
                   store.dispatch('operationlimit/osCheck', {
                     type: 1,
                     orderId: res.order_id,
                     checkOrDel: 'check',
                   }).then(response => {
                     if (response.data.code != 0) {
                       toast.error(response.data.data)
                     }
                     resolve(res)
                   })
                 })
               })
               .then((res) => {
                 return new Promise((resolve, reject) => {
                   //出库
                   store.dispatch('salesoutbound/autosave', {id: res.order_id,storeid: res.store_id}).then(response => {
                     if (response.data.code === 1) {
                       toast.error(response.data.data)
                     }
                     if (response.data.code === 0) {
                       //改状态
                       toast.success("审核成功")
                     }
                     resolve(res)
                   })
                 })
               })
               .then((res) => {
                 return new Promise((resolve, reject) => {
                   //删除
                   store.dispatch('operationlimit/osCheck', {
                     type: 1,
                     orderId: res.order_id,
                     checkOrDel: 'del',
                   }).then(response => {
                     resolve(res)
                   })
                 })
               })
     }
    const isInside = function (item){
      this.$swal({
        title: '是否切换内部交易状态',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          let is_inside = item.is_inside==null ? 1 : 1 - item.is_inside
          store.dispatch('offlinesalesorder/isInside', {id:item.id,is_inside:is_inside}).then(res => {
            this.$swal({
              icon: 'success',
              title: '已修改!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.refetchData()
          })
        }
      })
    }


    const createDeposit = function (id) {
      store.dispatch('salesorderdeposit/createDeposit', {orderId:id}).then(res => {
        if (res.data.code === 0) {
          toast.success("创建成功")
          this.refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      // refetchData,
      totalQty,
      totalReceive,

      // UI
    } = salesorderUseList(
            {
              id: router.route.value.query.id,
            }
    )

    return {
      ...toRefs(state),
      state,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      // refetchData,

      // Filter
      avatarText,
      isEmpty,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      toDate, toTime,
      // UI
      changeStatus,
      changeStatusF,
      createAutomatic,
      onSelect,
      close,
      storeInput,
      createStore,
      edit,
      saveRemark,
      advanced_search,
      fromChildren,
      reload,
      exports,
      showdriverModal,
      cleanCarDriver,
      showdriverExportsModal,
      delivery,
      Export,
      attachWh,
      saveAttachWh,
      attachWhLoaded,
      createStatement,

      getLodop,
      totalQty,
      totalReceive,
      pushBI,
      amountSubmit,
      amountSubmitSuccess,
      amountFunc,
      isInside,
      isNumber,
      createDeposit
    }
  },
  created() {
    this.edit()
    const userData = getUserData()
    this.user = userData
    //yang
    let result  =getCodeOptions('yesno')
    this.moneyIsLowZeroOptions =  result.filter((item)=>{
      return item.position===1;
    })
    //超管或者结算人员
    let settleArr = getCodeLabel("setting","user_settle")
    this.userIsSupperOrSettle = settleArr.includes(this.user.user_id)
    store.dispatch('warehouse/findWareHouseIds')
        .then(response => {
          let data = response.data.data
          for (let i = 0; i < data.length; i++) {
            this.warehouseArray.push({'key': i, "label": data[i].warehouse_name, "value": data[i].warehouse_id})
          }
        })
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.table-th {
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
