import axios from '@axios'
import qs from 'qs'
import { getUserData } from '@/auth/utils'

const user = getUserData()
export default {

  namespaced: true,
  state: {
    isSuper:user.role_id === 14,
    isFxyAndBl:user.user_id ===31 || user.user_id === 15
  },
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/offlinesalesorderitem/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/offlinesalesorderitem/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/offlinesalesorderitem/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/offlinesalesorderitem/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorderitem/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    addnewpros(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorderitem/addnewpro', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    caltotal(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorderitem/caltotal', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    updateOrderItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorderitem/updateOrderItem', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    delorderitem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorderitem/delorderitem', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    calextracost(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorderitem/calextracost', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    itemList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorderitem/itemList', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    modifyCostPrice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/offlinesalesorderitem/modifyCostPrice', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

  },
}
