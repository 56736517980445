import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    condition: {},
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateCondition(state,condition){
      state.condition = condition
    }
  },
  actions: {
    createSalesOrderLODOP(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/lodop/createSalesOrderLODOP', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createSalesOrderCarLODOP(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/lodop/createSalesOrderCarLODOP', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createPreSalesOrderDeliveryLODOP(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/lodop/createPreSalesOrderDeliveryLODOP', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createPurchaseInboundOrderDeliveryLODOP(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/lodop/createPurchaseInboundOrderDeliveryLODOP', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createPreSalesOrderDistributionLODOP(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/lodop/createPreSalesOrderDistributionLODOP', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createPurchaseInboundLODOP(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/lodop/createPurchaseInboundLODOP', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createAllotOutboundLODOP(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/lodop/createAllotOutboundLODOP', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createAllotOutboundOutboundLODOP(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/lodop/createAllotOutboundOutboundLODOP', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createPreSalesReturnBoundLODOP(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/lodop/createPreSalesReturnBoundLODOP', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createPreSalesReturnboundDistributionLODOP(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/lodop/createPreSalesReturnboundDistributionLODOP', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
