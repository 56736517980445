import { ref, watch, computed } from '@vue/composition-api'


// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from "@/store";
export default function mainTableItemUseList(config) {
  // Use toast
  const toast = useToast()


  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {key: 'ext', label: '商品69码'},
    {key: 'name', label: '名称'},
    {key: 'warehouse_name', label: '仓库'},
    { key: 'purchaseTeam', label: '销售权'},
    { key: 'production_date', label: '生产日期'},
    {key: 'status', label: '库存状态'},
    {key: 'location_full_name', label: '库位'},
    {key: 'is_gift', label: '赠品'},
    {key: 'product_cost', label: '售价 '},
    {key: 'product_cost_tax', label: '含税售价'},
    {key: 'tax_rate', label: '税率%'},
    {key: 'tax_subtotal', label: '税额 '},
    {key: 'cost', label: '金额'},
    {key: 'subtotal', label: '价税合计 '},
    {key: 'qty', label: '售卖数量'},
    {key: 'salesreturnqty', label: '错退数量'},
    {key: 'salesreturnqty1', label: '售后数量'},
    {key: 'stock_id', label: '批次ID'},
    {key: 'cost_price', label: '成本价'},
    {key: 'gross_profit_rate', label: '毛利率 '},
    {key: 'salesCost', label: '销售权成本 '},
    {key: 'salesCostGpr', label: '销售权毛利率 '},
    {key: 'purchaseCost', label: '原始采价 '},
    {key: 'purchaseCostGpr', label: '原始采价毛利率 '},
    //{key: 'cost_price_modifyed', label: '调整后成本'},

  ]

  if (store.state['offlinesalesorderitem'].isFxyAndBl){
    tableColumns.splice(tableColumns.length - 3,0,{ key: 'cost_price_modify', label: '成本调整值' })
  }
  if (store.state['offlinesalesorderitem'].isFxyAndBl){
    tableColumns.splice(tableColumns.length - 3,0,{ key: 'cost_price_modifyed', label: '调整后成本' })
  }

  if (store.state['offlinesalesorderitem'].isFxyAndBl){
    tableColumns.splice(tableColumns.length - 3,0,{ key: 'gross_profit_rate_modifyed', label: '调整后毛利率' })
  }

  const start = ref(1)
  const limit = ref(1000)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('item_id')
  const isSortDirDesc = ref(true)

  const isTrue = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('offlinesalesorderitem/itemList', {
        id : config.order_id,
      })
      .then(response => {
        const data = response.data.data
        callback(data)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '销售单明细列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
  }
}
